import React, { useEffect, useRef } from "react"
import { isMobile } from "react-device-detect"
import AuthUtility from "@/components/Default/AuthUtilityNav/v1/AuthUtilityNav.view"
import useAuthUtilityNavi18n from "@/i18n/useAuthUtilityNavi18n"
import _get from "lodash/get"
import { getConfig } from "@/constants/config"
import { logout, selectAuthState } from "@/store/features/authSlice"
import { useDispatch, useSelector } from "react-redux"
import styles from "@/components/Default/AuthUtilityNav/v1/AuthUtilityNav/index.module.scss"
// import useOnClickOutside from "@/hooks/useOnClickOutside"
import {
  getAuthMenuFragment,
  selectGenericState,
} from "@/store/features/genericSlice"

const AuthUtilityNav = props => {
  const { show, active, toggleAuthNav, setAuthNav, onAuthMenuClick } = props
  const dispatch = useDispatch()
  const authNavMenuRef = useRef(null)
  const { isAuth } = useSelector(selectAuthState)
  const { guestMenuFragment, userMenuFragment } =
    useSelector(selectGenericState)

  // useOnClickOutside(authNavMenuRef, toggleAuthNav)

  useEffect(() => {
    if (show || isMobile) {
      getConfig().then(CONFIG => {
        const userFragment = _get(CONFIG, "general.signInFragment", "")
        const guestFragment = _get(CONFIG, "general.signUpFragment", "")
        const guestMenuPayload = {
          reload: false,
          path: guestFragment,
          fragmentName: "guestMenuFragment",
        }
        dispatch(getAuthMenuFragment(guestMenuPayload))
        const userMenuPayload = {
          reload: false,
          path: userFragment,
          fragmentName: "userMenuFragment",
        }
        dispatch(getAuthMenuFragment(userMenuPayload))
      })
    }
  }, [show])

  const staticTexts = useAuthUtilityNavi18n()

  return (
    <div
      role="listitem"
      className={`auth-widget auth-widget__menu position_unset ${styles.authUtilityNavWrapper}`}
      ref={authNavMenuRef}
    >
      <AuthUtility
        show={show}
        active={active}
        toggleAuthNav={toggleAuthNav}
        i18n={staticTexts}
        userMenuFragment={userMenuFragment}
        guestMenuFragment={guestMenuFragment}
        isLoggedIn={isAuth}
        logout={() => dispatch(logout())}
        setAuthNav={setAuthNav}
        onAuthMenuClick={onAuthMenuClick}
      />
    </div>
  )
}

export default AuthUtilityNav

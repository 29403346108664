import MissingImage from "@/public/images/image-missing.png"
import { COMPONENT_TYPES, PLP_END_POINT_CLIENT } from "@/constants"
import { getConfig } from "@/constants/config"
import { store } from "@/store"
import { FREIGHT_SHIPPING_TYPES } from "@/constants"
import {
  POSTAL_CODE_US_REGEX,
  POSTAL_CODE_CA_REGEX,
  POSTAL_CODE_REGEX,
  SANITIZE_NUMBER_REGEX,
} from "@/constants/regex"
import { getCountryCode, getStateCode } from "@/utils/helper"

import { apim } from "@/constants/api"

const isContainer = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.CONTAINER)
}

const isProductcard = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PRODUCTCARD)
}

const isProductcardV3 = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PRODUCTCARD_V3)
}

const isAuthModal = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.AUTH_MODAL) && item?.hi
}

const isSiteWideWarning = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.SITEWIDE_WARNING)
}

const isNewsLetter = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.NEWSLETTER)
}

const isNewsLetterSignUp = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.NEWSLETTER_SIGNUP)
}

const isCompareProducts = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.COMPARE_PRODUCTS)
}

const isVideo = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.VIDEO)
}

const isSpacer = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.SPACER)
}

const isImage = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.IMAGE)
}

const isImageV3 = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.IMAGEV3)
}

const isUpSellContainer = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.UPSELL_CONTAINER)
}

const isCartLanding = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.CART_LANDING)
}

const isCheckoutLanding = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.CHECKOUT)
}

const isSharedCart = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.SHARED_CART)
}

const isOrderConfirmation = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.ORDER_CONFIRMATION)
}

const isOrderDetails = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.ORDER_DETAILS)
}

const isOrderHistory = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.ORDER_HISTORY)
}

const isOrderStatus = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.ORDER_STATUS)
}

const isPairsWellWth = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PAIRS_WELL_WITH)
}
const isCarousel = item => {
  return (
    item[":type"]?.includes(COMPONENT_TYPES.CAROUSEL) &&
    !item[":type"]?.includes(COMPONENT_TYPES.CAROUSELSLICK)
  )
}

const isSlick = item => {
  return (
    item[":type"]?.includes(COMPONENT_TYPES.SLICK) &&
    !item?.carouselComponentProperties?.appliedCssClassNames?.includes(
      "carousel-promo-banner"
    )
  )
}

const isPromoBanner = item => {
  return (
    item[":type"]?.includes(COMPONENT_TYPES.SLICK) &&
    item?.carouselComponentProperties?.appliedCssClassNames?.includes(
      "carousel-promo-banner"
    )
  )
}

const isAemForm = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.AEMFORM)
}
const isTeaser = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.TEASER)
}

const isTeaserContainer = data => {
  const types = []
  data[":itemsOrder"] &&
    data[":itemsOrder"].forEach(key => {
      const type = data[":items"][key][":type"]
      if (type) {
        types.push(type)
      }
    })
  return types.every(a => a.includes(COMPONENT_TYPES.TEASER))
}

const isText = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.TEXT)
}

const isTab = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.TAB)
}

const isBrandHeader = data => {
  const types = []
  data[":itemsOrder"] &&
    data[":itemsOrder"].forEach(key => {
      const type = data[":items"][key][":type"]
      if (type) {
        types.push(type)
      }
    })
  return (
    types.find(a => a.includes(COMPONENT_TYPES.BRAND_SWITCHER)) ||
    types.find(a => a.includes(COMPONENT_TYPES.UTILITY_NAV_HEADER))
  )
}

const checkNavigationV3 = obj => {
  // Check if the current object has the is_good property
  if (Object.keys(obj).some(key => key.includes("globalnavigation_v3"))) {
    return true
  }
  // Traverse each property of the object
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Check if the property is an object or an array
      if (typeof obj[key] === "object" && obj[key] !== null) {
        // Recursively check nested objects or arrays
        if (checkNavigationV3(obj[key])) {
          return true
        }
      }
    }
  }

  // Return false if no v3 property is found in the object or its nested objects
  return false
}
const isHeaderNav = data => {
  const types = []
  const itemsOrders = []
  data[":itemsOrder"] &&
    data[":itemsOrder"].forEach(key => {
      const type = data[":items"][key][":type"]
      if (data[":items"][key][":items"]) {
        itemsOrders.push(checkNavigationV3(data[":items"][key][":items"]))
      }
      if (type) {
        types.push(type)
      }
    })
  return (
    types.find(a => a.includes(COMPONENT_TYPES.UTILITY_WIDGET)) &&
    types.find(a => a.includes(COMPONENT_TYPES.TABS)) &&
    types.find(a => a.includes(COMPONENT_TYPES.IMAGE)) &&
    !itemsOrders.find(a => a === true)
  )
}

const isHeaderNavV3 = data => {
  const types = []
  const itemsOrders = []
  data[":itemsOrder"] &&
    data[":itemsOrder"].forEach(key => {
      const type = data[":items"][key][":type"]
      if (data[":items"][key][":items"]) {
        itemsOrders.push(checkNavigationV3(data[":items"][key][":items"]))
      }
      if (type) {
        types.push(type)
      }
    })
  return (
    types.find(a => a.includes(COMPONENT_TYPES.TABS)) &&
    types.find(a => a.includes(COMPONENT_TYPES.IMAGE)) &&
    types.find(a => a.includes(COMPONENT_TYPES.UTILITY_WIDGET)) &&
    itemsOrders.find(a => a === true)
  )
}

const isFooter = data => {
  const types = []
  data[":itemsOrder"] &&
    data[":itemsOrder"].forEach(key => {
      const type = data[":items"][key][":type"]
      if (type) {
        types.push(type)
      }
    })
  return (
    types.find(a => a.includes(COMPONENT_TYPES.MAIN_FOOTER)) ||
    types.find(a => a.includes(COMPONENT_TYPES.ADDITIONAL_FOOTER)) ||
    types.find(a => a.includes(COMPONENT_TYPES.NEWSLETTER_SIGNIN))
  )
}

const isEmbed = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.EMBED)
}

const isBreadcrumb = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.BREADCRUMB)
}

const isProductList = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PRODUCTLIST)
}

const isSearch = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.SEARCH)
}

const isToaster = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.TOASTER)
}

const isHelpSupport = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.HELPSUPPORT)
}
const isProductDetails = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PRODUCT_DETAILS)
}
const isAccountSettings = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.ACCOUNT_SETTINGS)
}

const isMyservice = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.MY_SERVICES)
}
const isMyFavorites = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.MY_FAVORITES)
}
export const isChangePassword = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.CHANGE_PASSWORD)
}

const isMyproducts = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.MY_PRODUCTS)
}

const isProductAccordion = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PRODUCT_DETAILS_ACCORDION)
}

const isProductReview = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PRODUCT_DETAILS_REVIEW)
}

const isRegisterOffline = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.REGISTER_OFFLINE_PRODUCTS)
}
const isRegisterOnline = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.REGISTER_ONLINE_PRODUCTS)
}
const isBrowseAllStores = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.BROWSE_ALL_STORES)
}

const isStoreFilter = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.STORE_FILTER)
}

const isProductExfPdp = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PRODUCT_DETAILS_EXFPDP)
}

const isKnowledgeArticle = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.KNOWLEDGE_ARTICLE)
}
const isLiterature = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.LITERATURE)
}

const isContactUs = item => {
  return item[":type"]?.includes(COMPONENT_TYPES?.CONTACT_US)
}

const isFindingModelNumber = item => {
  return item[":type"]?.includes(COMPONENT_TYPES?.FINDING_MODEL_NUMBER)
}

const isTechnicalSpec = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.TECHNICAL_SPECIFICATION)
}
const isPressLanding = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PRESS_LANDING)
}
const isFindStore = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.FIND_STORE)
}

const isInPageSearch = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.INPAGE_SEARCH)
}

const isTitle = (item = {}) => {
  return item[":type"]?.includes(COMPONENT_TYPES.TITLE)
}
const isKeySpecifier = (item = {}) => {
  return item[":type"]?.includes(COMPONENT_TYPES.KEY_SPECIFIER)
}

const isDynamicSearch = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.DYNAMIC_SEARCH)
}

const isAssociateMembership = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.ASSOCIATE_MEMBERSHIP)
}

const isInspireVideo = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.INSPIRE_VIDEO)
}

const isImageCluster = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.IMAGE_CLUSTER)
}
const isProfessionalDetail = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PROFESSIONAL_DETAILS)
}

const isRoomDetail = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.ROOM_DETAIL)
}

const isShopTheRoomDetail = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.SHOP_THE_ROOM_DETAIL)
}
const isglobalNavV3 = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.NAVIGATION_V3)
}

const isShopTheRoomCard = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.SHOP_THE_ROOM_CARD)
}

const isFindAProResults = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.FIND_A_PRO_RESULTS)
}

const isFriendsAndFamily = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.FRIENDS_AND_FAMILY)
}

const isInstallDesignServices = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.INSTALL_DESIGN_SERVICES)
}

const isFindAProByZip = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.FIND_A_PRO_BY_ZIP)
}

const isStoreAppointment = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.STORE_APPOINTMENT)
}

export const isGatedContent = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.GATED_CONTENT)
}

export const isProductCardV4 = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PRODUCT_CARD_V4)
}

export const isPDPCuratedProducts = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PDP_CURATED_PRODUCTS)
}

export const isShopTheRoomLandingPage = item =>
  item[":type"]?.includes(COMPONENT_TYPES.SHOP_THE_ROOM_PAGE)
/**
 *
 * @param {Element} e
 * @param {String} url
 */
const onImageError = (e, url) => {
  const imgsrc = e.target.src
  if (imgsrc.includes("&fmt=webp")) {
    e.target.src = imgsrc.replace("&fmt=webp", "")
    return
  }
  const attr = document.createAttribute("errorsrc")
  attr.value = url
  e.target.setAttributeNode(attr)
  e.target.src = MissingImage.src
}

/**
 *
 * @param {Number} width
 * @param {Number} rowCols
 * @param {String} swatchUrl
 * @param {String} assetId
 * @param {Object} presetConfigs
 * @param {Boolean} isForInstallationService
 * @return {String} preset url
 */
const getPresetUrl = (
  width = 0,
  rowCols = 0,
  swatchUrl = "",
  assetId = "",
  presetConfigs = {},
  isForInstallationService = false
) => {
  const {
    Desktop,
    MobilePortrait,
    MobilePortrait1,
    MobilePortrait2,
    MobileLandscape,
    MobileLandscape1,
    MobileLandscape2,
    PotraitCategory,
  } = presetConfigs
  let preset = ""

  const mobileBasedCheckPoint = isForInstallationService ? 1023 : 768
  if (width <= mobileBasedCheckPoint) {
    if (width <= 425) {
      if (rowCols) {
        preset = MobilePortrait2 || MobilePortrait
        if (rowCols === 1) preset = MobilePortrait1 || MobilePortrait
      } else if (MobilePortrait) {
        preset = MobilePortrait
      } else preset = MobilePortrait2
    } else {
      if (rowCols) {
        if (rowCols === 1) preset = MobileLandscape1 || MobileLandscape
        else preset = MobileLandscape2 || MobileLandscape
      } else if (MobileLandscape) {
        preset = MobileLandscape
      } else preset = MobileLandscape1
    }
  } else preset = Desktop

  if (isForInstallationService) {
    return `${swatchUrl}${assetId}?${preset}`
  }

  return `${swatchUrl}${PotraitCategory}?$product_src=is{PAWEB/${assetId}}&${preset}`
}

const alterClass = (elementClass = "", toAdd = null, toRemove = null) => {
  const element = document.getElementsByClassName(elementClass).item(0)
  if (toAdd && element) {
    element.classList.add(toAdd)
  }
  if (toRemove && element) {
    element.classList.remove(toRemove)
  }
}

//  * @param {String} fileName imagename.jpg / imagename
//  * @returns .jpg,.svg / false

const getExtention = fileName => {
  const i = fileName.lastIndexOf(".")
  if (i === -1) return false
  return fileName.slice(i)
}

/**
 *
 * @param {String} str
 * @param {Number} max
 * @param {Number} min
 * @return {Number}
 */
const accordionUniqueId = (str, max, min) => {
  const crypto = window.crypto || window.msCrypto
  const array = new Uint32Array(1)
  const randVal = crypto.getRandomValues(array)[0]
  return `${str}-${Math.floor(randVal * (max - min + 1)) + min}`
}

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

const closest = (el, fn) => el && (fn(el) ? el : closest(el.parentNode, fn))

export const removeExtraSpaces = (string = "") => {
  return string.replace(/\s+/g, " ").trim()
}

/**
 *
 * @param {String} swatchUrl
 * @param {String} assetId
 * @param {Object} presetConfigs
 * @param {String} assetAccountName
 * @return {String}
 */
const getPresetThumbnailUrl = (
  swatchUrl,
  assetId,
  presetConfigs,
  assetAccountName
) => {
  const { Thumbnail, LandscapeCategory } = presetConfigs
  return `${swatchUrl}${LandscapeCategory}?$product_src=is{${assetAccountName}/${assetId}}&${Thumbnail}`
}

const recursiveDataFunction = (data, type) => {
  const results = []
  if (data && data[":itemsOrder"]) {
    data[":itemsOrder"].map(key => {
      const item = data[":items"][key]

      if (item[":type"]?.includes(type)) {
        results.push(item)
        return item
      } else {
        const nestedResults = recursiveDataFunction(item, type)
        results.push(...nestedResults)
      }
    })
  }
  return results
}

const getRowCols = (width, isMobile, isTablet) => {
  let rowCols = width < 1024 ? 1 : 3
  switch (true) {
    case width > 1024:
      rowCols = 3
      break
    case (width < 990 && (isMobile || isTablet)) || width < 480:
      rowCols = 2
      break
    default:
      rowCols = 2
  }
  return rowCols
}

export const getBillingAsShippingAddress = (shipping, billing) => {
  const {
    billingStreetName = "",
    billingAptNo = "",
    billingCity = "",
    billingStateCode = "",
    billingZipcode = "",
  } = billing
  const {
    shippingStreetName = "",
    shippingAptNo = "",
    shippingCity = "",
    shippingStateCode = "",
    shippingZipcode = "",
  } = shipping

  return (
    billingStreetName.toLowerCase() === shippingStreetName.toLowerCase() &&
    billingAptNo.toLowerCase() === shippingAptNo.toLowerCase() &&
    billingCity.toLowerCase() === shippingCity.toLowerCase() &&
    billingStateCode.toLowerCase() === shippingStateCode.toLowerCase() &&
    billingZipcode.toLowerCase() === shippingZipcode.toLowerCase()
  )
}
export const getIsSameAddress = (add1, add2) => {
  const {
    streetName: streetName1 = "",
    streetNumber: streetNumber1 = "",
    city: city1 = "",
    state: state1 = "",
    postalCode: postalCode1 = "",
  } = add1
  const {
    streetName: streetName2 = "",
    streetNumber: streetNumber2 = "",
    city: city2 = "",
    state: state2 = "",
    postalCode: postalCode2 = "",
  } = add2

  return (
    streetName1.toLowerCase() === streetName2.toLowerCase() &&
    streetNumber1.toLowerCase() === streetNumber2.toLowerCase() &&
    city1.toLowerCase() === city2.toLowerCase() &&
    state1.toLowerCase() === state2.toLowerCase() &&
    postalCode1.toLowerCase() === postalCode2.toLowerCase()
  )
}

export const getShippingAllTotal = (customLineItems, locale) => {
  const shippings = []
  customLineItems.forEach((item = {}) => {
    const {
      custom: { fields: { isBundleParent = false } = {} } = {},
      name = {},
      totalPrice: { centAmount: totalPrice = 0 } = {},
    } = item

    const itemName = name[locale]
    const amount = totalPrice / 100 ?? 0
    if (isBundleParent) return false
    if (!shippings.find(el => el.name === itemName)) {
      shippings.push({ total: amount, name: itemName })
    } else {
      const index = shippings.findIndex(code => code.name === itemName)
      shippings[index].total += amount
    }
  })

  return shippings.filter(e => e.name !== "Service")
}

export const getShippingData = async (currencySign, freeShipping) => {
  const { cart: { cart: { customLineItems } = {} } = {} } = store.getState()
  const { internationalization: { locale } = {} } = await getConfig()

  const shippingAllTotal = getShippingAllTotal(customLineItems, locale)
  const freightShippingData =
    shippingAllTotal.find(el => FREIGHT_SHIPPING_TYPES.includes(el?.name)) ?? {}
  const parcelShippingData =
    shippingAllTotal.find(el => !FREIGHT_SHIPPING_TYPES.includes(el?.name)) ??
    {}

  const { total: freightAmount = "" } = freightShippingData
  const { total: parcelAmount = "" } = parcelShippingData

  const freightShippingCost =
    freightAmount > 0 ? `${currencySign}${freightAmount}.00` : freeShipping

  const parcelShippingCost =
    parcelAmount > 0 ? `${currencySign}${parcelAmount}.00` : freeShipping

  return { freightShippingCost, parcelShippingCost }
}

export const getOrderDetailsUrl = async (email, orderNumber) => {
  const config = await getConfig()
  const { general: { orderDetailsPath = "" } = {} } = config
  return `${orderDetailsPath}?orderNumber=${orderNumber}&q=${Buffer.from(
    email
  ).toString("base64")}`
}

export const getPdpRedirectUrl = (pdpUrl, param) => {
  const isHasQueryParams = pdpUrl.includes("?")
  return `${pdpUrl}${isHasQueryParams ? "&" : "?"}${param}`
}

export const isObjectEmpty = object => {
  return Object.keys(object).length === 0
}

/**
 *
 * @param {Array} data Array of Object
 * @param {String} key key name
 * @return {String} preset url
 */
export const getUniqueObjectListBy = (data, key) => {
  return [...new Map(data.map(e => [e[key], e])).values()]
}

export const affirmRefresh = () => {
  const affirmConfig = {
    public_api_key: process.env.NEXT_PUBLIC_AFFIRM_API_KEY,
    script: "https://cdn1.affirm.com/js/v2/affirm.js",
    session_id: "sID",
  }
  ;(function (l, g, m, e, a, f, b) {
    let d
    const c = l[m] || {}
    const h = document.createElement(f)
    const n = document.getElementsByTagName(f)[0]
    const k = function (a, b, c) {
      return function () {
        // eslint-disable-next-line prefer-rest-params
        a[b]._.push([c, arguments])
      }
    }
    c[e] = k(c, e, "set")
    // eslint-disable-next-line prefer-const
    d = c[e]
    c[a] = {}
    c[a]._ = []
    d._ = []
    c[a][b] = k(c, a, b)
    a = 0
    for (
      b =
        "set add save post open empty reset on off trigger ready setProduct".split(
          " "
        );
      a < b.length;
      a++
    )
      d[b[a]] = k(c, e, b[a])
    a = 0
    for (b = ["get", "token", "url", "items"]; a < b.length; a++)
      d[b[a]] = function () {}
    h.async = !0
    h.src = g[f]
    n.parentNode.insertBefore(h, n)
    delete g[f]
    d(g)
    l[m] = c
  })(window, affirmConfig, "affirm", "checkout", "ui", "script", "ready")
}

export const getPayPalPaymentIdFromParams = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get("id")
}

export const getBrandSwitcher = (headerData = {}) => {
  const headPropsItems = headerData[":items"]
  const headerPropsRoot = headPropsItems?.root
  const headerRootItems = headerPropsRoot[":items"]
  const hedaerPropsContainer = headerRootItems?.container_copy_copy
  const headerContainerItems = hedaerPropsContainer[":items"]
  const headerUtilityWidgets = headerContainerItems?.brand_switcher
  return headerUtilityWidgets?.items ?? ""
}

export const handleViewSterlingResults = (brandSwitcher, brandName, sku) => {
  const { link } = brandSwitcher?.find(
    item =>
      item.brandName.toLowerCase().replaceAll(" ", "") ===
      brandName.toLowerCase()
  )

  window.open(`${link}/en/search?keyword=${sku}`, "_blank")
}
export const findStoreUtilNavselectQuery =
  "EntityID,BPnumber,LocationName,AddressLine,AddressLine2,StateLong,AdminDistrict,PostalCode,Latitude,__Distance,Longitude,Phone,OpenUntil,Locality"

export const isString = data => {
  return data?.constructor.name === "String"
}

export const isObject = data => {
  return data?.constructor.name === "Object"
}

export const isValidPostalCode = (postalCode, countryCode) => {
  let postalCodeRegex
  switch (countryCode) {
    case "US":
      postalCodeRegex = POSTAL_CODE_US_REGEX
      break
    case "CA":
      postalCodeRegex = POSTAL_CODE_CA_REGEX
      break
    default:
      postalCodeRegex = POSTAL_CODE_REGEX
  }
  return postalCodeRegex.test(postalCode)
}

export const isLeadGeneration = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.Lead_Generation)
}

export const getSanitizeNumber = number => {
  return number.replace(SANITIZE_NUMBER_REGEX, "")
}
export const updateMetaContent = (metaName, value) => {
  const metas = document.querySelectorAll("meta")
  const itemList = Array.from(metas).filter(
    val => val?.name === metaName?.toLowerCase()
  )
  if (itemList && itemList.length != 0) {
    const lastElement = itemList.at(-1)
    lastElement.setAttribute("content", value)
    return lastElement
  }
  return false
}

export const parseToBoolean = inputVal => {
  if (inputVal === null || inputVal === undefined) {
    return false
  }
  if (typeof inputVal === "boolean") {
    return inputVal
  }
  if (typeof inputVal === "string") {
    return /true/i.test(inputVal)
  }
  return inputVal
}

/**
 *
 * @return {Object} {
 *  code: 'US',
 *  name: 'United States of America',
 *  states: [{code: 'AL', name: 'Alabama', countryCode: 'US'}]}
 */

export const getCountryStatesList = () => {
  const { generic: { countryList = [], stateList = [] } = {} } =
    store.getState()

  const countriesListData = countryList.map(country => {
    return {
      code: country.value,
      name: country.text,
      states: [],
    }
  })

  const countries = countriesListData.map(el => {
    const states = []
    stateList.forEach(state => {
      const [code, countryCode] = state.value?.split(":")

      if (countryCode === el.code) {
        states.push({
          code,
          name: state.text,
          countryCode,
        })
      }
    })

    return {
      ...el,
      states,
    }
  })

  return { countries }
}

export const formatAddress = addr => {
  const { countries } = getCountryStatesList()
  const {
    fname: { value: firstName = "" } = {},
    lname: { value: lastName = "" } = {},
    address1: { value: streetName = "" } = {},
    zipcode: { value: postalCode = "" } = {},
    city: { value: city = "" } = {},
    state: { value: state = "" } = {},
    country: { value: country = "" } = {},
    email: { value: email = "" } = {},
    phone: { value: phone = "" } = {},
    label: { show: setPrimary = "", value: label = "" } = {},
    address2: streetNumber = "",
    id = "",
  } = addr

  const address = {
    firstName,
    lastName,
    streetName,
    streetNumber,
    postalCode,
    city,
    state: getStateCode(state, country, countries),
    country: getCountryCode(country, countries),
    email,
    phone,
    setPrimary,
    custom: {
      type: { typeId: "type", key: "custom-address" },
      fields: { label },
    },
    id,
  }
  return address
}

export const mapCollapsedSku = async (data = {}) => {
  const { itemSkus = [], cart = {}, item = [], isLineItems = false } = data
  const {
    generic: {
      globalConfig: { internationalization: { language = "en" } = {} } = {},
    } = {},
  } = store?.getState()

  const skuQury = getSkusQuery(itemSkus)
  const url = `${PLP_END_POINT_CLIENT}?fq=language_s:(${language})&fq=sku_s:(${skuQury})&rows=10&start=0&fl=sku_s,isCollapsedPDP_b,masterSKU_s&persona=GST&profilename=Search`

  const { data: { response: { docs: docsData = [] } = {} } = {} } = skuQury
    ? await apim.get(url)
    : {}

  if (!docsData.length) {
    if (isLineItems) {
      return item
    } else if (!cart.item) {
      cart.item = []
      return cart
    } else return cart
  }

  const itemsData = isLineItems ? item : cart?.item ?? []

  const cartItemsMaped = itemsData.map(e => {
    const { isCollapsedPDP_b: isCollapsedPDP = false } =
      docsData.find(el => {
        return `K-${el.sku_s}` === e.productInfo.productID
      }) ?? {}
    e.productInfo.productSkuOption = isCollapsedPDP
    return e
  })

  cart.item = cartItemsMaped
  return isLineItems ? cartItemsMaped : cart
}

/**
 *
 * @param {Array} skus  Array
 * @return {String} '"22972-CP", "32380-CH05-BLL"'
 */
export const getSkusQuery = (skus = []) => {
  let query = ""
  skus.forEach((sku, i) => {
    query += `"${sku}"`
    if (i + 1 !== skus.length) query += ", "
  })
  return query
}

export {
  getPresetUrl,
  onImageError,
  isContainer,
  isAuthModal,
  isSiteWideWarning,
  isNewsLetter,
  isNewsLetterSignUp,
  isCompareProducts,
  isVideo,
  isImage,
  isImageV3,
  isSpacer,
  isCarousel,
  isPromoBanner,
  isSlick,
  isCartLanding,
  isPairsWellWth,
  isTeaser,
  isTeaserContainer,
  isText,
  isTab,
  isBrandHeader,
  isHeaderNav,
  isFooter,
  isEmbed,
  isCheckoutLanding,
  isSharedCart,
  isOrderConfirmation,
  alterClass,
  isOrderDetails,
  isOrderHistory,
  isOrderStatus,
  accordionUniqueId,
  getExtention,
  ConditionalWrapper,
  closest,
  isBreadcrumb,
  isProductList,
  isProductDetails,
  isSearch,
  isToaster,
  isHelpSupport,
  isAccountSettings,
  isMyservice,
  isMyproducts,
  isProductAccordion,
  isProductReview,
  isRegisterOffline,
  isRegisterOnline,
  isMyFavorites,
  getPresetThumbnailUrl,
  isAemForm,
  isProductcard,
  isProductcardV3,
  recursiveDataFunction,
  isProductExfPdp,
  isKnowledgeArticle,
  isBrowseAllStores,
  isLiterature,
  isContactUs,
  isFindingModelNumber,
  isTechnicalSpec,
  isPressLanding,
  getRowCols,
  isUpSellContainer,
  isFindStore,
  isStoreFilter,
  isInPageSearch,
  isTitle,
  isKeySpecifier,
  isDynamicSearch,
  isAssociateMembership,
  isInspireVideo,
  isImageCluster,
  isRoomDetail,
  isShopTheRoomDetail,
  isglobalNavV3,
  isHeaderNavV3,
  isShopTheRoomCard,
  isInstallDesignServices,
  isProfessionalDetail,
  isFriendsAndFamily,
  isFindAProByZip,
  isFindAProResults,
  isStoreAppointment,
}

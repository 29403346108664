import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import Link from "next/link"
import styles from "@/components/core/Button/Button.module.scss"
import backArrow from "@/public/images/backArrow.png"
import Image from "@/components/Shared/Image"
const Button = forwardRef((props, ref) => {
  const {
    label,
    size,
    arrowImg,
    type,
    flexible,
    disabled,
    onClick,
    customClass,
    to,
    target,
    className,
    loading,
    role,
    tag,
    tabIndex = 0,
    ariaLabel,
    parentClass,
    useDefaultClass,
    testid = "",
    ...remainingProps
  } = props
  const buttonClass = cx(
    "kf-react-button",
    "button",
    className ? className : null,
    size ? size : "large",
    type ? type : "primary",
    flexible ? "flexible" : null,
    disabled ? "disabled" : null,
    customClass ? customClass : null,
    loading ? "loading" : null
  )
  const ButtonTag =
    tag === "button" || role === "button"
      ? "button"
      : tag === "anchor"
      ? "a"
      : Link
  const buttonFullClass = useDefaultClass
    ? styles.buttonWrapper
    : styles.buttonWrapper + " " + buttonClass + " " + (parentClass || "")
  return (
    <div className={buttonFullClass}>
      <ButtonTag
        {...remainingProps}
        className={buttonClass}
        onClick={onClick}
        href={to ?? ""}
        target={target}
        role={role ?? ""}
        tabIndex={tabIndex}
        aria-label={ariaLabel}
        aria-disabled={disabled}
        data-testid={`button${testid ? `-${testid}` : ""}`}
        ref={ref}
      >
        {arrowImg && (
          <span className="arrowImage">
            <Image src={backArrow} alt="backArrow " />
          </span>
        )}
        {loading && <div className="loader"></div>}
        {label}
      </ButtonTag>
    </div>
  )
})

Button.displayName = "Button"

export default Button

Button.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  flexible: PropTypes.bool,
  customClass: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  parentClass: PropTypes.string,
}

export const getSessionIdObjWithEpoc = () => {
  const cookies = document.cookie.split(";") ?? []
  let sessionCookie =
    cookies.find(myCookie => myCookie.includes("session#")) ?? ""
  sessionCookie =
    sessionCookie.split("|").find(item => item.includes("session")) ?? ""
  const sessionCookies = sessionCookie.split("#") ?? []
  const sessionId = sessionCookies[1] ?? ""
  return sessionId ? `${sessionId}-${Date.now()}` : ""
}

export const getSessionId = () => {
  const cookies = document.cookie.split(";") ?? []
  let sessionCookie =
    cookies.find(myCookie => myCookie.includes("session#")) ?? ""
  sessionCookie =
    sessionCookie.split("|").find(item => item.includes("session")) ?? ""
  const sessionCookies = sessionCookie.split("#") ?? []
  return sessionCookies[1] ?? ""
}

export const getTntId = () => {
  const cookies = document.cookie.split(";") ?? []
  let tntCookie = decodeURIComponent(
    cookies.find(myCookie => myCookie.includes("AMCV_")) ?? ""
  )
  tntCookie = tntCookie.split("|") ?? ""
  return tntCookie[4] ?? ""
}

export const getUserListLineItems = results => {
  const listItems = {}
  results.forEach(list => {
    list?.lineItems.forEach(lineItem => {
      listItems[lineItem?.productId] = {
        ...lineItem,
        listName: list?.name,
        listId: list?.id,
        listVersion: list?.version,
      }
    })
  })
  return listItems
}

import {
  PURE_DATA_FOR_STRING_REGEX,
  SPECIAL_CHAR_REGEX,
} from "@/constants/regex"
import {
  getLinkFromHref,
  pushAnalyticsObjToDataLayer,
  removeAndCleanLink,
} from "@/utils/helper"

export const getEventInfo = (
  pageType = "",
  componentPosition = "",
  mainTitle = "",
  eventLabel = "",
  dataLayerLinkType = "",
  eventType = "",
  zone = "",
  parseJson = false
) => {
  const zoneName = zone ? zone?.replace(SPECIAL_CHAR_REGEX, " ") : "zone"
  let internalLinkType = ""
  if (eventLabel === "print") {
    internalLinkType = `${pageType.toLowerCase()}:${mainTitle.toLowerCase()}:print`
  } else {
    internalLinkType = `${dataLayerLinkType}`
  }

  const internalLinkZoneName = zone
    ? `${pageType.toLowerCase()}:${zoneName}`
    : "teaser"

  const eventInfo = {
    clickInternalLinks: "true",
    internalLinkPosition: `${pageType.toLowerCase()}:${
      componentPosition ? componentPosition : "teaser"
    }`,
    internalLinkZoneName,
    internalLinkURL: "n/a",
    internalLinkName: `${eventLabel}`,
    eventName: `${pageType.toLowerCase()}:${
      eventLabel === "print" ? eventLabel : `${zoneName}:share`
    }`,
    internalLinkType: internalLinkType,
    eventType: eventType,
    eventAction: `${pageType.toLowerCase()}:${
      eventLabel === "print" ? eventLabel : `${zoneName}:share`
    }`,
  }
  if (parseJson) {
    return eventInfo
  }
  return JSON.stringify(eventInfo)
}

export const addDatalayerShare = (data = "{}", isPdp = false) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const eventInfo = JSON.parse(data)
  const dataLayerObj = {
    event: "cmp:click",
    page,
    eventInfo,
  }
  if (isPdp) {
    const { productInfo } = page
    dataLayerObj["productInfo"] = productInfo
  }
  window.adobeDataLayer.push(dataLayerObj)
}

export const getDownloadEventInfo = (
  downLoadLink = "",
  pageType = "",
  componentPosition = "",
  mainTitle = "",
  dataLayerLinkType = ""
) => {
  let eventInfo = {}
  if (downLoadLink) {
    eventInfo = {
      clickInternalLinks: "true",
      internalLinkPosition: `${pageType.toLowerCase()}:${
        componentPosition ? componentPosition : "teaser"
      }`,
      internalLinkZoneName: "teaser",
      internalLinkURL: `${downLoadLink}`,
      internalLinkName: "download",
      eventName: `${pageType.toLowerCase()}:download`,
      internalLinkType: `${pageType.toLowerCase()}:${mainTitle.toLowerCase()}:download`,
      eventType: "teaser",
      eventAction: `${pageType.toLowerCase()}:download`,
    }
  } else {
    eventInfo = {
      clickInternalLinks: "true",
      internalLinkPosition: `${pageType.toLowerCase()}:${
        componentPosition || "teaser"
      }`,
      internalLinkZoneName: "teaser",
      internalLinkURL: "n/a",
      internalLinkName: "download",
      eventName: `${pageType.toLowerCase()}:download`,
      internalLinkType: `${dataLayerLinkType}`,
      eventType: "teaser",
      eventAction: `${pageType.toLowerCase()}:download`,
    }
  }

  return JSON.stringify(eventInfo)
}

export const getTitleEventInfo = (
  linkNewTab = false,
  componentPosition = "",
  linkURL = "",
  mainTitle = "",
  pageType = "",
  componentLinkType = "",
  title = ""
) => {
  return `{"clickInternalLinks":"${
    linkNewTab || mainTitle.toLowerCase().includes("kohler canada")
      ? "false"
      : "true"
  }","internalLinkPosition":"${pageType?.toLowerCase()}:${
    componentPosition || "teaser"
  }","internalLinkZoneName":"teaser","internalLinkURL":"${
    linkURL || getLinkFromHref(title)
  }","internalLinkName":"${mainTitle.toLowerCase()}","eventName":"${pageType.toLowerCase()}:zone:${mainTitle.toLowerCase()}","internalLinkType":"${pageType}:${
    componentLinkType ? componentLinkType : mainTitle.toLowerCase()
  }","eventType":"teaser","eventAction":"${pageType.toLowerCase()}:zone:${mainTitle.toLowerCase()}"}`
}

export const addTeaserActionAnalytics = ({
  newTab,
  pageType = "",
  componentPosition = "",
  videoTitle = "",
  link = "",
  text = "",
  eventName: eventNameDatalayer,
  dataLayerLinkType = "",
  eventAction: eventActionDataLayer,
  productCategory = "",
  productCollectionsName = "",
  productRoom = "",
  datalayerPageType = "",
  mainTitle = "",
  componentInfo = "",
}) => {
  let eventAction = `${pageType?.toLowerCase()}:${
    eventActionDataLayer?.toLowerCase() || mainTitle || "zone"
  }:${text?.toLowerCase()}`
  let eventName = `${pageType?.toLowerCase()}:${
    eventNameDatalayer?.toLowerCase() || mainTitle || "zone"
  }:${text?.toLowerCase()}`
  let internalLinkZoneName = `${
    videoTitle || pageType.toLowerCase() === "plp"
      ? `${pageType.toLowerCase()}:hero banner`
      : mainTitle
      ? `${pageType.toLowerCase()}:${mainTitle}`
      : `teaser`
  }`
  let internalLinkPosition = `${pageType?.toLowerCase()}:${
    componentPosition || "teaser"
  }`
  let internalLinkType = `${
    videoTitle.replace(PURE_DATA_FOR_STRING_REGEX, "")
      ? `${pageType.toLowerCase()}:${videoTitle.replace(
          PURE_DATA_FOR_STRING_REGEX,
          ""
        )}`
      : `${pageType.toLowerCase()}:${mainTitle}`
  }`
  if (dataLayerLinkType.includes("social impact")) {
    eventName = `${dataLayerLinkType.substring(
      0,
      dataLayerLinkType.lastIndexOf(":")
    )}:${text?.toLowerCase()}`
    eventAction = `${dataLayerLinkType.substring(
      0,
      dataLayerLinkType.lastIndexOf(":")
    )}:${text?.toLowerCase()}`
    internalLinkZoneName = `${dataLayerLinkType.substring(
      0,
      dataLayerLinkType.lastIndexOf(":")
    )}`
  }
  let eventType = "navigation"
  if (datalayerPageType.includes("locations")) {
    pageType = "locations:kohler stores"
    eventName = `${pageType?.toLowerCase()}:${text?.toLowerCase()}`
    eventAction = `${pageType?.toLowerCase()}:${text?.toLowerCase()}`
    internalLinkZoneName = pageType?.toLowerCase()
    internalLinkPosition = `${pageType?.toLowerCase()}:teaser`
    internalLinkType = `${pageType.toLowerCase()}:${mainTitle}`
  }
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  if (
    (text === "Get Started" || text === "Apply Now") &&
    eventActionDataLayer === null
  ) {
    const NewDatalayerPageType =
      datalayerPageType === "services"
        ? page?.category?.subCategoryID
        : datalayerPageType
    eventAction = text.toLowerCase()
    eventName = text.toLowerCase()
    eventType = "promo banner"
    internalLinkPosition = `${NewDatalayerPageType?.toLowerCase()}:promo banner`
    internalLinkType = `${pageType.toLowerCase()}:${mainTitle}`
    internalLinkZoneName = `${NewDatalayerPageType?.toLowerCase()}:promo details`
  }

  const eventInfo = {
    clickInternalLinks:
      link?.startsWith("/") ||
      link?.startsWith("#") ||
      link?.includes(window.location.hostname)
        ? "true"
        : "false",
    internalLinkPosition,
    internalLinkZoneName,
    internalLinkURL: link
      ? `${
          link.indexOf("http") === -1 ? window.location.origin : ""
        }${removeAndCleanLink(link)}`
      : "n/a",
    internalLinkName: `${!text ? "explore more arrow" : text.toLowerCase()}`,
    eventName,
    internalLinkType,
    eventType,
    eventAction,
    eventMsg: "n/a",
    eventStatus: "n/a",
  }

  const customDataLayer = {
    ...(componentInfo ? { componentInfo: JSON.parse(componentInfo) } : {}),
    productInfo: {
      productCategory: productCategory || "n/a",
      productCollectionsName: productCollectionsName || "n/a",
      productRoom: productRoom || "n/a",
    },
  }

  pushAnalyticsObjToDataLayer(eventInfo, "cmp:click", {}, customDataLayer)
}

export const addTeaserDescriptionAnalytic = (
  target = "",
  isPdp = "",
  isPlp = "",
  pageType = "",
  internalLinkPositionNotPdp = "",
  preTitle = "",
  mainTitle = "",
  linkURL = "",
  internalLinkTypeNotPdp = "",
  sanitizeDescription = ""
) => {
  return JSON.stringify({
    clickInternalLinks: target === "_blank" ? "false" : "true",
    internalLinkPosition:
      isPdp || isPlp ? pageType.toLowerCase() : internalLinkPositionNotPdp,
    internalLinkZoneName: isPlp
      ? `${pageType.toLowerCase()}:${preTitle}:rm`
      : mainTitle
      ? `${pageType?.toLowerCase()}:${mainTitle}`
      : "teaser",
    internalLinkURL: linkURL,
    eventName: isPlp
      ? `${pageType.toLowerCase()}:${preTitle}:rm`
      : `${pageType.toLowerCase()}:${mainTitle}:rm`,
    internalLinkType: isPdp
      ? `${pageType.toLowerCase()}:teaser`
      : isPlp
      ? `${pageType.toLowerCase()}:navigation`
      : internalLinkTypeNotPdp,
    eventType: isPdp || isPlp ? "navigation" : "teaser",
    internalLinkName: sanitizeDescription,
    eventMsg: "n/a",
    eventStatus: "n/a",
    eventAction: isPlp
      ? `${pageType.toLowerCase()}:${preTitle}:rm`
      : `${pageType.toLowerCase()}:${mainTitle}:rm`,
  })
}

export const addTeaserAnalytic = (
  linkNewTab = false,
  componentPosition = "",
  linkURL = "",
  mainTitle = "",
  pageType = "",
  componentLinkType = "",
  title,
  datalayerLinkName = "",
  containerIdName = "",
  eventName = "",
  eventAction = ""
) => {
  return `{"clickInternalLinks":"${
    linkNewTab ? "false" : "true"
  }","internalLinkPosition":"${pageType?.toLowerCase()}:${
    componentPosition || "teaser"
  }","internalLinkZoneName":"teaser","internalLinkURL":"${linkURL}","internalLinkName":"${datalayerLinkName}","eventName":"${pageType?.toLowerCase()}:${containerIdName}:${
    eventName || datalayerLinkName || "rm"
  }","internalLinkType":"${pageType}:${
    componentLinkType || mainTitle
  }","eventType":"teaser","eventAction":"${pageType?.toLowerCase()}:${containerIdName}:${
    eventAction || datalayerLinkName || "rm"
  }"}`
}

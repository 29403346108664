/* eslint-disable new-cap */
import React, { useEffect, useState } from "react"
import Head from "next/head"
import { Provider, useDispatch, useSelector, useStore } from "react-redux"
import _isEmpty from "lodash/isEmpty"
import { isMobile } from "react-device-detect"
import dynamic from "next/dynamic"
import { PersistGate } from "redux-persist/integration/react"
import { useRouter } from "next/router"
import { appWithTranslation, useTranslation } from "next-i18next"
import useIsSsr from "@/hooks/useIsSsr"
import axios from "axios"
import Script from "next/script"

import { wrapper } from "@/store"
import { setPageObject } from "@/store/features/genericSlice"
import { selectCartState } from "@/store/features/cartSlice"
import { compareState } from "@/store/features/compareSlice"

import { initConfig, getConfig } from "@/constants/config"
import { initAuth } from "@/services/auth.service"

import { PDP_REGEX } from "@/constants/regex"
import { FEATURE_FLAGS_API_ENDPOINT } from "@/constants/routes"
import {
  EXCLUDE_PAGES,
  FIND_A_PRO_PATH,
  SUPPORT_CENTER_PATH,
  INSTALLATION_SERVICES_PATH,
  CART_URL,
  CHECKOUT_URL,
  TECHNICAL_SPECIFICATION_PATH,
  LOCATIONS_PATH,
  pageUrl,
  GATED_HASHES,
} from "@/constants"

import {
  handleEnter,
  addPageLoadAnalyticsEvent,
  calculateCartData,
  createPersonaCookie,
  getAEMPreviewModeCookie,
  closePreview,
  getMyLocationByUserIP,
  getMyLocation,
  getRandom,
  getAnonymousId,
} from "@/utils/helper"
import { affirmRefresh, mapCollapsedSku } from "@/utils/"
import { ConditionalWrapper } from "@/utils"

import {
  selectGenericState,
  setPageIsIntercative,
  setFeatureFlagsFeature,
  setGlobalConfig,
} from "@/store/features/genericSlice"
import {
  setCityLocation,
  locationState,
  setUtilityLocation,
} from "@/store/features/locationSlice"
import {
  selectAuthState,
  setAuthModalFormData,
  setAuthModalVisibility,
} from "@/store/features/authSlice"

import nextI18nConfig from "../next-i18next.config"

import PageLoader from "@/components/core/PageLoader/PageLoader"
import ErrorBoundary from "@/components/Shared/ErrorBoundary"
import Layout from "@/components/Shared/Layout"
const AuthModal = dynamic(() => import("@/components/Shared/AuthModal/v1"), {
  ssr: false,
})
const CartActionModal = dynamic(
  () => import("@/components/Shared/CartActionModal"),
  {
    ssr: false,
  }
)
const NewsLetterSignUpOverlay = dynamic(
  () =>
    import("@/components/NewsLetterSignUpOverlay/v1/NewsLetterSignUpOverlay"),
  {
    ssr: false,
  }
)
const CompareProducts = dynamic(
  () =>
    process.env.NEXT_PUBLIC_COMPARE_PRODUCTS_VERSION === "compare_products_v1"
      ? import("@/components/CompareProducts/v1/CompareProducts")
      : import("@/components/CompareProducts/v2/CompareProducts"),
  {
    ssr: false,
  }
)
const PromoModal = dynamic(
  () => import("@/components/Default/PromoBanner/v1/PromoModal"),
  {
    ssr: false,
  }
)

const Toaster = dynamic(() => import("@/components/core/Toaster/Toaster"), {
  ssr: false,
})

const PreviewCart = dynamic(
  () => import("@/components/PreviewCart/v1/PreviewCart"),
  {
    ssr: false,
  }
)

import closeIcon from "@/public/icons/close.svg"

import "@/styles/index.scss"
import "@/styles/index_main.scss"
import "@/styles/clientlib-site.min.css"
import "@/styles/clientlib-base.min.css"
import "@/styles/overrides.scss"
import "react-datepicker/dist/react-datepicker.css"

if (typeof window === "undefined") React.useLayoutEffect = React.useEffect

const MyApp = wrapper.withRedux(({ Component, pageProps }) => {
  const store = useStore()
  const dispatch = useDispatch()
  const router = useRouter()
  const isSsr = useIsSsr()
  const {
    isLoading = false,
    globalScripts,
    globalStyles,
    dataLayerObj,
    pageIsInteractive,
    toaster: { isVisible },
    menu: { isOpen },
    previewCart: { showPreview },
    newslettersignup,
    eventPageType,
    compareproducts,
    promoBannerModal,
    isProductInfo,
    isPreviewCartInit = false,
    gatedContentData,
  } = useSelector(selectGenericState)

  const { utilityLocation = "" } = useSelector(locationState)
  const language = useTranslation()
  const { compare } = router.query
  const { comparedProducts } = useSelector(compareState)
  const [showCompare, setShowCompare] = useState(false)
  const [previewMode, setPreviewMode] = useState(false)
  const { authModal: { show } = {}, showCartAction = false } =
    useSelector(selectAuthState)
  const { cart: cartObj } = useSelector(selectCartState)

  const [scriptsLoaded, setScriptsLoaded] = useState(0)
  const [showCampaign, setShowCampaign] = useState(false)
  const isRestricted = EXCLUDE_PAGES.indexOf(router.pathname) >= 0
  const [pageAlternateLinks] = useState(
    process.env.NEXT_PUBLIC_ALTERNATIVE_HOSTS
      ? process.env.NEXT_PUBLIC_ALTERNATIVE_HOSTS.split(",").map(item => {
          const [lang = "", baseUrl = ""] = item?.split(":") ?? []
          return { lang, baseUrl }
        })
      : []
  )

  useEffect(() => {
    affirmRefresh()

    initConfig().then(async () => {
      const config = await getConfig()
      dispatch(setGlobalConfig(config))
      await initAuth(config)
    })
    const authModalData =
      pageProps?.footertmp?.[":items"]?.root?.[":items"]?.authmodal ?? {}
    dispatch(setAuthModalFormData(authModalData))

    window.adobeDataLayer = window.adobeDataLayer || []
    document.body.addEventListener("keypress", handleEnter)
    createPersonaCookie()

    if (getAEMPreviewModeCookie()) {
      setPreviewMode(true)
    } else {
      setPreviewMode(false)
    }
    if (!utilityLocation) checkForLocation()
    return () => document.body.removeEventListener("keypress", handleEnter)
  }, [])

  useEffect(() => {
    if (!_isEmpty(gatedContentData)) checkForGatedContentPaths()
  }, [gatedContentData])

  const checkForGatedContentPaths = () => {
    if (window) {
      const path = window.location?.pathname
      const { gatedContentPathsParameters = [] } = gatedContentData
      if (gatedContentPathsParameters.length) {
        const matchedGatedContent =
          gatedContentPathsParameters.find(item => {
            const { gatedURL = "" } = item
            const securedPath = gatedURL.substring(
              gatedURL.indexOf(pageUrl),
              gatedURL.length
            )
            return securedPath === path
          }) ?? {}
        const { gatedURL = "", landingPageURL = "" } = matchedGatedContent
        const oktaTokenBrowStorage = localStorage.getItem("okta-token-storage")
        const oktaTokenStorage = oktaTokenBrowStorage
          ? JSON.parse(oktaTokenBrowStorage)
          : {}
        const isAnonymous = _isEmpty(oktaTokenStorage)
        if (gatedURL && landingPageURL && isAnonymous) {
          const redirectBackUrl =
            landingPageURL.substring(
              landingPageURL.indexOf("/en"),
              landingPageURL.length
            ) ?? "/en"
          dispatch(
            setAuthModalVisibility({
              show: true,
              onCloseHandlation: () => handleCloseOfModal(redirectBackUrl),
            })
          )
        }
      }
    }
  }

  const handleCloseOfModal = (url = "") => {
    window.location.replace(url)
  }

  const checkForLocation = () => {
    const currentZipCode = localStorage.getItem("currentZipCode")

    if (currentZipCode) {
      dispatch(
        setUtilityLocation({
          utilityLocation: currentZipCode,
        })
      )
      return
    }
    getMyLocation()
      .then(getLocation => {
        const details = {
          utilityLocation: getLocation.zipcode ?? "",
          userCity: getLocation.city ?? "",
        }
        dispatch(setCityLocation(details))
      })
      .catch(err => {
        getMyLocationByUserIP()
          .then(getLocation => {
            const details = {
              utilityLocation: getLocation.zipcode ?? "",
              userCity: getLocation.city ?? "",
            }
            dispatch(setCityLocation(details))
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.log(error)
          })
      })
  }

  useEffect(() => {
    let queueDelay
    if (cartObj?.id) {
      calculateCartData({ cart: cartObj }, true).then(async res => {
        // added timeout for queueing to the callback stack
        // fix for cart not updating to dataLayer sometimes
        const { cart: { itemSkus, ...cartData } = {} } = res
        res.cart = await mapCollapsedSku({ itemSkus, cart: cartData })

        queueDelay = setTimeout(() => {
          window.adobeDataLayer.forEach(function (part, index, dataLayer) {
            if (dataLayer[index].event) {
              const cartItems = res?.cart?.item ?? []
              const cartItemStr = cartItems
                .map(item => item.productInfo?.productID ?? "")
                .join()
              dataLayer[index].page.cartInfo = { cartItems: cartItemStr }
              dataLayer[index].page.cart = res?.cart
              const pageObj = dataLayer[index].page || window.pageObj
              const { category: { pageType = "" } = {} } = pageObj

              const pageObjEvent = window.adobeDataLayer.filter(data => {
                return data.event === "cmp:show"
              })
              if (pageObjEvent && pageObjEvent.length > 0) {
                const pageObjEventTmp = { ...pageObjEvent }
                pageObjEventTmp.page = pageObj
                pageObjEventTmp.event = "cmp:tmpShow"
                if (window?.productInfo && pageType === "pdp") {
                  pageObjEventTmp.productInfo = JSON.parse(window?.productInfo)
                  pageObjEventTmp.pageInfo = pageObj.pageInfo
                }
                window.adobeDataLayer.push(pageObjEventTmp)
                window.adobeDataLayer.pop()
              }
            }
          })
        }, 500)
      })
    }
    return () => clearTimeout(queueDelay)
  }, [cartObj, router])

  useEffect(() => {
    const onRouterChangeStart = url => {
      dispatch(setPageIsIntercative(false))
      window.referrer = window.location.href
      concernBanner("remove")
      clearAdobeTargetVideoTimeout()
    }

    const onRouterChangeComplete = (_, urlProp) => {
      if (window && window.adobeDataLayer) {
        // prevent the dalatalayer events in case of shallow routing from PDP page
        if (!urlProp?.shallow) {
          window.adobeDataLayer.length = 0
          window.datalayerTopLoaded = false
          dispatch(setPageObject(false))
        }
      }
      alterBodyClass()
      const isPdp = window?.eventPageType === "pdp"
      if (!isPdp) {
        window.datalayerTopLoaded = false
        window.initDataLayerTop()
      }
      window.isShowAnalyticsEvent = true
      addPageLoadAnalyticsEvent()
      delete window.referrer
      dispatch(setPageIsIntercative(true))
      concernBanner("add")
      if (pageIsInteractive) appendAdobeTargetVideoScript()
    }

    const popStateListener = e => {
      const queryString = window.location.search
      const path = window.location.pathname
      const urlParams = new URLSearchParams(queryString)
      const hashedUrl = window.location.hash
      const eventState = e?.state
      if (hashedUrl) {
        const hashUrl = hashedUrl.split("::")[0] ?? ""
        const redirectionPath = hashedUrl.split("::")[1] ?? ""
        if (GATED_HASHES.includes(hashUrl.toLowerCase())) {
          if (getAnonymousId()) {
            let active = 0
            if (hashUrl.includes("#signup")) active = 1
            dispatch(setAuthModalVisibility({ show: true, active }))
            window.gatedPathRedirection = redirectionPath
          } else {
            window.location.href = redirectionPath
          }
        }
        if (hashedUrl === "#newsletter") {
          setShowCampaign(true)
        }
      }

      if (
        urlParams.has("back") ||
        urlParams.has("keyword") ||
        path == "/en/support/help-and-faq" ||
        path == SUPPORT_CENTER_PATH ||
        path == FIND_A_PRO_PATH ||
        (path == INSTALLATION_SERVICES_PATH &&
          ((eventState !== null && _isEmpty(eventState)) ||
            eventState?.previousUrl === CART_URL ||
            eventState?.previousUrl === CHECKOUT_URL)) ||
        path.includes(TECHNICAL_SPECIFICATION_PATH) ||
        path.includes(LOCATIONS_PATH) ||
        urlParams.has("compare") ||
        PDP_REGEX.test(path)
      ) {
        router.reload()
      }
    }

    window.addEventListener("popstate", popStateListener)

    router.events.on("routeChangeStart", onRouterChangeStart)
    router.events.on("routeChangeComplete", onRouterChangeComplete)

    return () => {
      // concernBanner("remove")
      router.events.off("routeChangeStart", onRouterChangeStart)
      router.events.off("routeChangeComplete", onRouterChangeComplete)
      window.removeEventListener("popstate", popStateListener)
    }
  }, [router])

  const alterBodyClass = () => {
    if (pageProps?.tmpData?.cssClassNames) {
      document.body.className = pageProps?.tmpData?.cssClassNames
    }
  }

  useEffect(() => {
    if (dataLayerObj) {
      const pageObj = JSON.parse(dataLayerObj)
      const { pageInfo: { pageUrl = "" } = {} } = pageObj
      if (pageUrl.includes(".json")) {
        pageObj.pageInfo.pageUrl = window.location.href
      }
      window.pageObj = pageObj
      window.eventPageType = eventPageType
      window.isShowAnalyticsEvent = true
      const isPdpLoaded =
        (window?.eventPageType === "pdp" && isProductInfo) ||
        window?.eventPageType !== "pdp"
      if (window.pageObj && !window.datalayerTopLoaded && isPdpLoaded) {
        const dataLayerEvent = new Event("datalayerTopFired")
        window.dispatchEvent(dataLayerEvent)
      }
    }
  }, [dataLayerObj, isProductInfo])

  useEffect(() => {
    const loadScripts = () => {
      dispatch(setPageIsIntercative(true))
    }

    const loadScriptsTimer = setTimeout(loadScripts, 5000)
    const userInteractionEvents = [
      "mouseover",
      "keydown",
      "touchmove",
      "touchstart",
      "scroll",
      "click",
    ]

    const triggerScriptLoader = () => {
      loadScripts()
      clearTimeout(loadScriptsTimer)
      userInteractionEvents.forEach(function (event) {
        window.removeEventListener(event, triggerScriptLoader, {
          passive: true,
        })
      })
    }

    userInteractionEvents.forEach(function (event) {
      window.addEventListener(event, triggerScriptLoader, {
        passive: true,
      })
    })
    concernBanner("add")
    // setTimeout(ontrustCookie(), 1000)
    return () => {
      dispatch(setPageIsIntercative(false))
      concernBanner("remove")
    }
  }, [])

  const concernBanner = action => {
    const oneTrustVal = window.OneTrust || window.OneTrustStub
    if (oneTrustVal && oneTrustVal.ToggleInfoDisplay) {
      if (action === "add") {
        oneTrustVal.initializeCookiePolicyHtml()
        oneTrustVal.LoadBanner()
      }

      document
        .querySelectorAll("#ot-sdk-link")[0]
        ?.removeEventListener("click", function () {
          oneTrustVal.ToggleInfoDisplay()
        })
      if (action === "add") {
        document
          .querySelectorAll("#ot-sdk-link")[0]
          ?.addEventListener("click", function () {
            oneTrustVal.ToggleInfoDisplay()
          })
      }
    }
  }
  useEffect(() => {
    // To remove hide the chat window on openinig menu
    if (isMobile) {
      const chatElement = document.getElementsByClassName(
        "embeddedServiceHelpButton"
      )
      if (isOpen && chatElement.length) {
        chatElement.item(0).style.display = "none"
      } else if (chatElement.length) {
        chatElement.item(0).style.display = "block"
      }
    }
  }, [isOpen])
  useEffect(() => {
    if (compare) {
      setShowCompare(true)
    } else if (comparedProducts && comparedProducts.length > 0) {
      setShowCompare(true)
    } else {
      document.body.classList.remove(
        "compare-open",
        "scroll-lock",
        "modal-open"
      )
      setShowCompare(false)
    }
  }, [comparedProducts])

  let adobeTargetVideoTimer
  let adobeTargetElValidateCount = 0
  const appendAdobeTargetVideoScript = () => {
    adobeTargetElValidateCount += 1
    const adobeTargetVideoContainer =
      document.getElementsByClassName("at-element-marker")
    const videoEl = document.getElementsByTagName("video")
    if (adobeTargetVideoTimer) {
      clearTimeout(adobeTargetVideoTimer)
      adobeTargetVideoTimer = undefined
    }
    if (adobeTargetVideoContainer.length > 0 && videoEl.length > 0) {
      const scriptPath = "/js/video.js"
      let script = document.querySelector(`script[src="${scriptPath}"]`)
      if (!script) {
        script = document.createElement("script")
        script.src = scriptPath
        script.defer = true
        document.body.appendChild(script)
      }
    } else if (adobeTargetElValidateCount <= 10) {
      adobeTargetVideoTimer = setTimeout(() => {
        appendAdobeTargetVideoScript()
      }, 1000)
    }
  }

  const clearAdobeTargetVideoTimeout = () => {
    if (adobeTargetVideoTimer) {
      clearTimeout(adobeTargetVideoTimer)
      adobeTargetVideoTimer = undefined
      adobeTargetElValidateCount = 0
    }
  }

  useEffect(() => {
    if (pageIsInteractive) appendAdobeTargetVideoScript()
    return () => {
      clearAdobeTargetVideoTimeout()
    }
  }, [pageIsInteractive])

  const handleModalClose = () => {
    if (showCampaign) setShowCampaign(false)
  }

  const getFeatureFlags = async () => {
    const { data: featureFlags = {} } = await axios.get(
      FEATURE_FLAGS_API_ENDPOINT
    )
    dispatch(setFeatureFlagsFeature(featureFlags))
  }
  useEffect(() => {
    getFeatureFlags()
  }, [])

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {pageAlternateLinks.length
          ? pageAlternateLinks.map(({ lang = "", baseUrl = "" }, index) => (
              <link
                rel="alternate"
                hrefLang={lang}
                key={`page-alterante-link-8csi3-3232k-sae${index}12`}
                href={`https://${baseUrl}${lang === "x-default" ? "/en" : ""}${
                  pageProps.urlPath === "/" ? "" : pageProps.urlPath
                }`}
              />
            ))
          : null}
        {globalStyles.map((style, i) => {
          return (
            <React.Fragment key={`style-${i}-${getRandom()}`}>
              <style jsx global>
                {style}
              </style>
            </React.Fragment>
          )
        })}
        {previewMode && <meta name="robots" content="noindex,nofollow" />}
        {pageProps.metaContent?.length ? (
          <meta name="robots" content={pageProps?.metaContent} />
        ) : null}
        {pageProps?.canonicalUrl ? (
          <link
            rel="canonical"
            href={
              language?.i18n?.language === "fr"
                ? pageProps?.canonicalUrl?.replace("/en/", "/fr/")
                : pageProps?.canonicalUrl
            }
          />
        ) : null}
      </Head>

      <Provider store={store}>
        <ConditionalWrapper
          condition={!isSsr}
          wrapper={children => (
            <PersistGate
              persistor={store.__persistor}
              loading={<PageLoader initialLoader={true} />}
            >
              {children}
            </PersistGate>
          )}
        >
          <PageLoader loading={isLoading} />
          <Layout show={promoBannerModal?.show}>
            <ErrorBoundary>
              <div className={`root ${previewMode ? "previewmode" : ""}`}>
                <Component {...pageProps} />
              </div>
              {show && <AuthModal />}
              {showCartAction ? <CartActionModal /> : null}
              {isVisible && <Toaster message={""} />}
              {newslettersignup && (
                <NewsLetterSignUpOverlay
                  data={newslettersignup}
                  showCampaign={showCampaign}
                  handleModalClose={handleModalClose}
                />
              )}
              {promoBannerModal?.show && (
                <PromoModal {...promoBannerModal.modalContent} />
              )}
              {!isRestricted && showCompare && compareproducts?.content && (
                <CompareProducts compareproducts={compareproducts?.content} />
              )}
              {showPreview || isPreviewCartInit ? <PreviewCart /> : null}
            </ErrorBoundary>
          </Layout>
        </ConditionalWrapper>
      </Provider>

      {pageIsInteractive &&
        globalScripts.map((script, i) => {
          if (!script.innerHTML && scriptsLoaded === i)
            return (
              <Script
                onReady={() => {
                  setScriptsLoaded(i + 1)
                }}
                onError={() => {
                  setScriptsLoaded(i + 1)
                }}
                key={`script-${i}`}
                src={script.src}
                id={`script-${i}`}
              />
            )
          if (script.innerHTML && scriptsLoaded === i)
            return (
              <Script
                onReady={() => {
                  setScriptsLoaded(i + 1)
                }}
                onError={() => {
                  setScriptsLoaded(i + 1)
                }}
                key={`innerscript-${i}`}
                id={`innerscript-${i}`}
              >
                {`${script.innerHTML}`}
              </Script>
            )
        })}

      <Script
        src="/js/datalayer.js"
        onReady={() => {
          const dataLayerEvent = new Event("datalayerFired")
          window.dispatchEvent(dataLayerEvent)
        }}
      />

      <Script src="/js/basic-text-module.js" />

      {previewMode && (
        <div className="topcorner">
          <span>
            Decoupled preview mode
            <img
              className="ml-10 mr-5"
              onClick={() => closePreview()}
              src={closeIcon.src}
              alt="close-icon"
            />
          </span>
        </div>
      )}
    </>
  )
})

/**
 * function to log webvitals
 * @param {*} metric
 */
export function reportWebVitals(metric) {
  console.log("Web Vitals", metric)
}

export default appWithTranslation(MyApp, nextI18nConfig)

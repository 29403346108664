import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import DOMPurify from "isomorphic-dompurify"
import htmlParse from "html-react-parser"
import fileDownload from "js-file-download"
import contentDisposition from "content-disposition"
import { getConfig } from "@/constants/config"
import { getLocationUsingIP } from "@/utils/location"
import { getShippingAllTotal } from "@/utils/"
import { apim } from "@/constants/api"
import { FREIGHT_SHIPPING_TYPES } from "@/constants/"
import { store } from "@/store"
import Cookie from "universal-cookie"
import moment from "moment"
import {
  setGlobalScripts,
  setGlobalStyles,
  setAnalyticsScript,
  zipDownload,
  setDataLayerObj,
  showToast,
} from "@/store/features/genericSlice"
import {
  setCart,
  setCartCount,
  setQtyLoading,
} from "@/store/features/cartSlice"

import { getQuantityCount } from "@/js/helperV2"
import {
  BAZAAR_VOICE,
  COMPONENT_TYPES,
  DOWNLOAD_SPINNER,
  LOCALE_ENGLISH,
  PDP_TYPE,
  queryParamsToPersist,
} from "@/constants/index"
import { setAuthModalVisibility } from "@/store/features/authSlice"
import {
  ALPHABET_WITH_SPACE_REGEX,
  FILTER_PARAM_REGEX,
  PURE_TEXT_REGEX,
  SANITIZE_TEXT_REGEX,
} from "@/constants/regex"
import { isUpSellContainer } from "."
import redirectsData from "@/public/redirect/redirectmap.json"
import { addMedia, removeItems } from "@/store/features/favoritesSlice"

const isServer = typeof window === "undefined"

const getMyLocation = () => {
  return new Promise((resolve, reject) => {
    getConfig().then(CONFIG => {
      navigator.geolocation.getCurrentPosition(
        async position => {
          try {
            const res = await getCurrentLocationLatLng(position)
            resolve({
              city: res.city ?? "",
              zipcode: res.zipcode ?? "",
            })
          } catch (e) {
            const errorCause = {
              title: _get(CONFIG, "general.geoLocationErrorTitle", "").trim(),
              message: _get(
                CONFIG,
                "general.geoLocationErrorMessage",
                ""
              ).trim(),
              ctaLink: _get(
                CONFIG,
                "general.geoLocationErrorCtaLink",
                ""
              ).trim(),
              ctaLabel: _get(
                CONFIG,
                "general.geoLocationErrorCtaLabel",
                ""
              ).trim(),
            }
            reject(new Error("Location Error", { cause: errorCause }))
          }
        },
        e => {
          const errorCause = {
            title: _get(CONFIG, "general.geoLocationErrorTitle", "").trim(),
            message: _get(CONFIG, "general.geoLocationErrorMessage", "").trim(),
            ctaLink: _get(CONFIG, "general.geoLocationErrorCtaLink", "").trim(),
            ctaLabel: _get(
              CONFIG,
              "general.geoLocationErrorCtaLabel",
              ""
            ).trim(),
          }
          reject(new Error("Location Error", { cause: errorCause }))
        }
      )
    })
  })
}

const getMyCity = async (reverseGeo, lat, long) => {
  const { general } = await getConfig()
  const siteName = general.siteName ?? ""
  try {
    const res = await apim.get(reverseGeo, {
      params: {
        $format: "json",
        brand: siteName.toLowerCase(),
        lat: lat,
        long: long,
      },
    })
    const response = res.data.resourceSets[0].resources[0].address
    return {
      city: _get(response, "adminDistrict2", ""),
      zipcode: _get(response, "postalCode", ""),
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Fetching City failed", err)
  }
}

const getApiServletPath = async () => {
  const CONFIG = await getConfig()
  const res = CONFIG.apiEndpoints
  return {
    findStores: _get(res, "findStores", ""),
    reverseGeo: _get(res, "reverseGeo", ""),
    findStoresBingKey: _get(res, "findStoresBingKey", ""),
  }
}

const getCurrentLocationLatLng = async position => {
  const getReverseGeo = await getApiServletPath()
  const reverseGeoLink = getReverseGeo.reverseGeo ?? ""
  let getCityData = {}

  try {
    const lat = localStorage.getItem("latitude")
    const lng = localStorage.getItem("longitude")
    const cityData = localStorage.getItem("cityData")
    const locationTimeout = localStorage.getItem("locationTimeout")

    if (
      parseFloat(lat) === position.coords.latitude &&
      parseFloat(lng) === position.coords.longitude &&
      cityData &&
      locationTimeout &&
      moment().isBefore(moment(locationTimeout))
    ) {
      getCityData = JSON.parse(cityData)
    } else {
      getCityData = await getMyCity(
        reverseGeoLink,
        position.coords.latitude,
        position.coords.longitude
      )
      const localStorageData = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        cityData: JSON.stringify(getCityData),
        locationTimeout: moment().add("30", "minutes").toISOString(true),
      }
      setLocalStorageData(localStorageData)
    }
  } catch (err) {
    clearLocalStorageData(
      "latitude",
      "longitude",
      "cityData",
      "locationTimeout"
    )
  }

  return {
    city: _get(getCityData, "city", ""),
    zipcode: _get(getCityData, "zipcode", ""),
  }
}

const clearLocalStorageData = (...keys) => {
  keys.forEach(key => {
    localStorage.removeItem(key)
  })
}

const setLocalStorageData = localStorageData => {
  for (const [key, value] of Object.entries(localStorageData)) {
    localStorage.setItem(key, value)
  }
}

const getMyLocationByUserIP = async () => {
  const response = await getUserIP()
  return {
    city: response.city ?? "",
    zipcode: response.zipcode ?? "",
  }
}

const getUserIP = async () => {
  const res = await getLocationUsingIP()
  return {
    city: res?.data?.data?.C ?? "",
    zipcode: res?.data?.data?.Z ?? "",
  }
}

const getLocationByCookies = async () => {
  const userLocation =
    document.cookie.match("(^|;)\\s*userLocation\\s*=\\s*([^;]+)")?.pop() || ""
  let getCityData = {}
  if (userLocation !== "") {
    try {
      const splitLocation = userLocation.split("+")
      const lat = localStorage.getItem("cookiesLatitude")
      const lng = localStorage.getItem("cookiesLongitude")
      const cityData = localStorage.getItem("cookiesCityData")
      const locationTimeout = localStorage.getItem("cookiesLocationTimeout")

      if (
        parseFloat(lat) === splitLocation[4] &&
        parseFloat(lng) === splitLocation[5] &&
        cityData &&
        locationTimeout &&
        moment().isBefore(moment(locationTimeout))
      ) {
        getCityData = JSON.parse(cityData)
      } else {
        const getReverseGeo = await getApiServletPath()

        const reverseGeoLink = getReverseGeo.reverseGeo ?? ""
        getCityData = await getMyCity(
          reverseGeoLink,
          splitLocation[4],
          splitLocation[5]
        )
        const localStorageData = {
          cookiesLatitude: splitLocation[4],
          cookiesLongitude: splitLocation[5],
          cookiesCityData: JSON.stringify(getCityData),
          cookiesLocationTimeout: moment()
            .add("30", "minutes")
            .toISOString(true),
        }
        setLocalStorageData(localStorageData)
      }
    } catch (err) {
      clearLocalStorageData(
        "cookiesLatitude",
        "cookiesLongitude",
        "cookiesCityData",
        "cookiesLocationTimeout"
      )
    }
  }

  return {
    city: _get(getCityData, "city", ""),
    zipcode: _get(getCityData, "zipcode", ""),
  }
}

const sanitizeInnerHtml = (property, options = {}) =>
  htmlParse(
    `${DOMPurify.sanitize(property, { ADD_ATTR: ["target"] })}`,
    options
  )

const customSanitizeInnerHtml = property =>
  htmlParse(
    `${DOMPurify.sanitize(property, {
      ADD_ATTR: [
        "target",
        "frameborder",
        "scrolling",
        "allowfullscreen",
        "height",
        "src",
        "width",
        "style",
        "alt",
        "lang",
        "title",
      ],
      ALLOWED_TAGS: [
        "iframe",
        "p",
        "img",
        "a",
        "br",
        "span",
        "ul",
        "ol",
        "li",
        "div",
        "b",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "strong",
        "button",
        "video",
        "svg",
        "hr",
      ],
    })}`
  )

const formatPhoneNumber = phoneNumberString => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "")
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3]
  }
  return null
}

export const validatePassword = async (password, userData) => {
  const regexObj = await getPasswordRegExp()
  const regex = new RegExp(regexObj.regex)
  const data = userData.filter(el => el.length > 0)
  if (password.length < 10) return false
  if (!regex.test(password)) return false
  if (data.some(vl => password.toLowerCase().includes(vl.toLowerCase())))
    if (!password.match(/(?=.*[A-Z])/)) {
      return false
    }
  return true
}

const validateField = async (field, value) => {
  const { regexPattern } = await getConfig()
  const pattern = _get(regexPattern, `map.${field}`, "")
  const regex = new RegExp(pattern)
  return !!regex.test(value)
}

const addToRecentSearch = keyword => {
  if (keyword && keyword.length > 2) {
    let history = JSON.parse(window.localStorage.getItem("keyWordList")) ?? []
    history = history.filter(
      item => item.keyWord.toLowerCase() !== keyword.toLowerCase()
    )
    history.unshift({ keyWord: keyword, id: Date.now() })
    window.localStorage.setItem("keyWordList", JSON.stringify(history))
  }
}

const navigationType = () => {
  let result
  let p

  if (window.performance.getEntriesByType("navigation")) {
    p = window.performance.getEntriesByType("navigation")[0].type

    if (p === "navigate") result = 0
    if (p === "reload") result = 1
    if (p === "back_forward") result = 2
    if (p === "prerender") result = 3
  }
  return result
}

const getParamFromUrl = (param, urlLocation = "") => {
  const url = new URL(urlLocation || location.href)
  const appliedSort = url.searchParams.get(param) ?? ""
  return appliedSort.includes("%2B")
    ? appliedSort.replaceAll("%2B", "+")
    : appliedSort.replaceAll(" ", "+")
}

const smoothScroll = element =>
  element.scrollIntoView({
    behavior: "smooth",
    block: "nearest",
    inline: "nearest",
  })

const getClienIP = request => {
  let ip = ""
  if (!request) return ip
  if (request.headers["IpAddress"]) {
    ip = request.headers["IpAddress"].split(",")[0]
  } else if (request.headers["true-ip"]) {
    ip = request.headers["true-ip"].split(",")[0]
  } else if (request.headers["x-forwarded-for"]) {
    ip = request.headers["x-forwarded-for"].split(",")[0]
  } else if (request.headers["x-real-ip"]) {
    ip = request.connection.remoteAddress
  } else {
    ip = request.connection.remoteAddress || ""
  }
  return ip
}

const isProtectedUrl = async url => {
  const CONFIG = await getConfig()
  const {
    commerce: {
      sessionAuth: { securePagePathList },
    },
  } = CONFIG
  const curUrl = url ?? window.location.pathname
  return securePagePathList.includes(curUrl)
}

const updateHeader = (token, isAuth = false) => {
  const authHeader = `Bearer ${token}`
  apim.defaults.headers.common["Authorization"] = authHeader

  // if (isAuth) {
  //   clearTimeout(refreshTimer)
  //   setTimeout(() => {
  //     addPageLoadAnalyticsEvent()
  //   }, 2000)
  //   return
  // }

  // addPageLoadAnalyticsEvent()
  // renewAnonymousTokenBeforeExpiry(token.access_token)
}
// eslint-disable-next-line require-jsdoc
function debounce(func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      // eslint-disable-next-line no-invalid-this
      func.apply(this, args)
    }, timeout)
  }
}

const shaEncrypt = async message => {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message)

  // hash the message
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer)

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  // convert bytes to hex string
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join("")
  // return a promise
  return hashHex
}

const caesarEncrypt = (salt, text) => {
  const textToChars = text => text.split("").map(c => c.charCodeAt(0))
  const byteHex = n => ("0" + Number(n).toString(16)).substr(-2)
  const applySaltToChar = code =>
    textToChars(salt).reduce((a, b) => a ^ b, code)

  return text
    .split("")
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join("")
}

const caesarDecrypt = (salt, encoded) => {
  const textToChars = text => text.split("").map(c => c.charCodeAt(0))
  const applySaltToChar = code =>
    textToChars(salt).reduce((a, b) => a ^ b, code)
  return encoded
    .match(/.{1,2}/g)
    .map(hex => parseInt(hex, 16))
    .map(applySaltToChar)
    .map(charCode => String.fromCharCode(charCode))
    .join("")
}

const getPasswordRegExp = async () => {
  const { regex } = await getConfig()
  return {
    regex: regex.regex ?? "",
    passwordMessage: regex.conditionsList ?? [],
  }
}

const getUserAnalyticsObject = (data = {}) => {
  const { isFromRegisterUser = false } = data

  const hashedEmail =
    localStorage.getItem("hashedEmail") ||
    store?.getState()?.auth?.hashedEmail ||
    ""
  const customerProspect =
    localStorage.getItem("customerProspect") ||
    store?.getState()?.auth?.customerProspect
  let isCustomerOrProspect = "n/a"
  if (hashedEmail && customerProspect) {
    try {
      const decryptedJSON = JSON.parse(
        caesarDecrypt(hashedEmail, customerProspect)
      )
      if (decryptedJSON.pastOrders.length) {
        isCustomerOrProspect = "customer"
      } else {
        isCustomerOrProspect = "prospect"
      }
    } catch (es) {
      // eslint-disable-next-line no-console
      console.log("decrypt error: ", es)
    }
  }

  const url = new URL(window.location.href)
  const encodedEmailId = url.searchParams?.get("q") ?? ""
  // this user object should be updated when user log in or out.
  const user = {
    gId: "n/a",
    abTestName: "n/a",
    adBlocker: false,
    authStatus: "anonymous",
    commerceToolsID: "n/a",
    customerProspect: isCustomerOrProspect,
    envName: "development",
    knownStatus: "unknown",
    profileRole: "guest",
    profileType: "n/a",
    returningStatus: "new visitor",
    emailId: hashedEmail || encodedEmailId || "n/a",
    encodedEmailId,
  }
  const isOrderConfirmationPage =
    window?.eventPageType === "orders:order confirmation"
  if (isOrderConfirmationPage) {
    localStorage.removeItem("hashedEmail")
  }

  // fetch okta tokens for both logged in and anonymous users
  const oktaToken = localStorage.getItem("okta-token-storage")
  const anonymousOktaToken =
    localStorage.getItem("okta-anonymous-access-token") ||
    store?.getState()?.auth?.access_token
  if (oktaToken && oktaToken !== "{}") {
    // user is logged in
    const oktaObject = JSON.parse(oktaToken)
    const claim = oktaObject?.accessToken?.claims || {}
    // user.uId = claim.uid || "n/a";
    if (claim.businessType === "Consumer" || claim.businessType === "homeowner") {
      user.profileRole = "consumer"
      user.profileType = "consumer"
    } else {
      user.profileRole = "professional"
      const businessSubType =
        claim?.businessSubType !== undefined && claim?.businessSubType !== null
          ? `|${claim.businessSubType}`
          : ""
      user.profileType = `${claim.businessType}${businessSubType}`
    }
    user.commerceToolsID = claim.commercetoolsID || "n/a"
    if (!isFromRegisterUser) {
      user.returningStatus = "returning customer"
    }
    user.authStatus = "logged in"
    user.knownStatus = "known"
  } else if (anonymousOktaToken) {
    // anonymous user
    let oktaObject
    try {
      oktaObject = JSON.parse(atob(anonymousOktaToken.split(".")[1])) || {}
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn("Anonymous Okta Token can not be decoded......")
    }
    if (oktaObject) {
      user.gId = oktaObject.gId || "n/a"
    }
  }
  return user
}

const isElementExcluded = (e, classNames) => {
  const path = e.path || (e.composedPath && e.composedPath())
  return path.some(element =>
    classNames.some(className => element?.className?.includes(className))
  )
}

const clearAuthToken = () => {
  localStorage.removeItem("customerProspect")
  localStorage.removeItem("okta-cache-storage")
  localStorage.removeItem("okta-anonymous-access-token")
  localStorage.removeItem("anonymousId")
  const cookies = new Cookie()
  cookies.remove("commerce_gid", { path: "/", domain: ".kohler.com" })
}

const clearAnonymousToken = () => {
  localStorage.removeItem("anonymousId")
  localStorage.removeItem("okta-anonymous-access-token")
  localStorage.removeItem("okta-anonymous-refresh-token")
  localStorage.removeItem("token-revalidate-expiry")
  const cookies = new Cookie()
  cookies.remove("commerce_gid", { path: "/", domain: ".kohler.com" })
}

const SAFE_URL_PATTERN =
  /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi

/** A pattern that matches safe data URLs. It only matches image, video, and audio types. */
const DATA_URL_PATTERN =
  /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+\/]+=*$/i

const _sanitizeUrl = url => {
  url = String(url)
  if (url === "null" || url.length === 0 || url === "about:blank")
    return "about:blank"
  if (url.match(SAFE_URL_PATTERN) || url.match(DATA_URL_PATTERN)) return url

  return `unsafe:${url}`
}

const sanitizeUrl = (url = "about:blank") => {
  return _sanitizeUrl(String(url).trim())
}

const revalidateTime = () => moment().add(30, "m").format()

const getPDPUrl = async (category, slug) => {
  const { plpServletPath, general } = await getConfig()
  const pdpUrl = gePDPUrlPageDetails(plpServletPath, general, category, slug)
  return pdpUrl
}

const getPDPUrlForProductTile = async data => {
  const {
    Product_Category: productCategory = "",
    ProductLocalCategory_s: productLocalCategory = "",
    slug_s: slug,
  } = data

  const category = productCategory
    ? productCategory
    : productLocalCategory ?? ""

  const { plpServletPath, general } = await getConfig()
  return gePDPUrlPageDetails(plpServletPath, general, category, slug)
}

const getPDPUrlWithConfig = (category, slug, plpServletPath, general) => {
  const pdpUrl = gePDPUrlPageDetails(plpServletPath, general, category, slug)
  return pdpUrl
}
const gePDPUrlPageDetails = (plpServletPath, general, category, slug) => {
  const path = plpServletPath?.find(i => i.categories.includes(category))
  const shortUrl = general.shortendPagePath ?? ""
  if (category === "Design Services") {
    return getShortenedUrlwithGeneral(general.designServiceLandingPath, general)
  }
  if (category === "Install Services") {
    return getShortenedUrlwithGeneral(
      general.installServiceLandingPath,
      general
    )
  }
  if (!category || category === "Service Parts") {
    return `${general.servicePartsLandingPath?.replace(shortUrl, "")}/${slug}`
  }
  if (path) {
    return `${path.url?.replace(shortUrl, "")}/${slug}`
  }

  return `${getShortenedUrlwithGeneral(general.plpPagePath, general)}/${category
    ?.replace(/\s&\s|\s/g, "-")
    .toLowerCase()}/${slug}.html`
}

const pdpTypeFormat = (data = {}) => {
  const {
    businessUnitName = "",
    ProductIsRetail = "",
    ProductRequiredAccessoriesGroupsList = [],
    superSku = "",
  } = data
  let pdpType = ""

  if (businessUnitName === "Service Parts") {
    pdpType = "regular part"
  } else if (ProductIsRetail) {
    pdpType = "retail exclusive"
  } else if (ProductRequiredAccessoriesGroupsList.length) {
    pdpType = PDP_TYPE
  } else if (superSku) {
    pdpType = "super sku"
  } else {
    pdpType = PDP_TYPE
  }
  return pdpType
}

const sanitizeTextForAnalytics = (text = "") => {
  const testData = text ?? ""
  return String(testData).replace(SANITIZE_TEXT_REGEX, "").toLowerCase().trim()
}

const getSwatchCount = (isPlp = false) => {
  if (!isPlp && window.outerWidth < 1330) {
    return 3
  } else if (isPlp && window.outerWidth < 812) {
    return 5
  }
  return isPlp ? 7 : 5
}

const isColorFacet = f => {
  const facet = f.replace(/^\*{2}/, "")
  return !!(facet === "ColorFinish_s" || facet === "SKUColorFinishName_ss")
}

const getCurrentSkuCouponDiscounts = ({ sku, cart, locale }) => {
  // find if cart-discount is applied to this product
  const discountID = []
  const discountedAmount = []
  const productCouponCode = []
  const comparedList = []
  const codesList = []
  const globalDiscountNames = []
  let productCoupondiscount = 0
  let globalDiscountValues = 0

  cart?.lineItems?.forEach(lineItem => {
    const discountSku = _get(lineItem, "variant.sku", null)
    // finding discount details in lineItems
    if (discountSku === sku) {
      _get(lineItem, "discountedPrice.includedDiscounts", []).forEach(
        includedDiscount => {
          if (
            includedDiscount?.discount?.typeId === "cart-discount" &&
            includedDiscount.discount.obj?.id
          ) {
            discountID.push(includedDiscount.discount.obj.id)
            discountedAmount.push(
              Number(
                (includedDiscount.discountedAmount.centAmount / 100).toFixed(2)
              )
            )
            codesList.push(includedDiscount.discount.obj.name[locale])
          }
        }
      )
    }
  })
  if (discountID.length && cart?.discountCodes?.length) {
    // we got discount id from lineItems, now get discount code from discountCodes
    cart?.discountCodes.forEach(discountCode => {
      if (discountCode?.discountCode?.obj?.code) {
        discountCode.discountCode.obj.cartDiscounts?.forEach(cartDiscount => {
          const matchingIndex = discountID.indexOf(cartDiscount.id)
          if (matchingIndex !== -1 && cartDiscount.typeId === "cart-discount") {
            // copy discount code of this discountCode object
            productCouponCode.push(discountCode.discountCode.obj.code)
            productCoupondiscount += discountedAmount[matchingIndex]
            comparedList.push(cartDiscount.id)
          }
        })
      }
    })
  }
  discountID
    .filter(a => !comparedList.includes(a))
    .forEach(a => {
      const matchedIndex = discountID.indexOf(a)
      if (matchedIndex >= 0) {
        globalDiscountNames.push(codesList[matchedIndex])
        globalDiscountValues += discountedAmount[matchedIndex]
      }
    })
  productCoupondiscount = Number(productCoupondiscount.toFixed(2))
  return {
    productCouponCode,
    productCoupondiscount,
    globalDiscountNames,
    globalDiscountValues,
  }
}

const destructureAddress = (addr, siteName) => {
  const data = { address: "", city: "", state: "", zipcode: "" }
  data.address = addr.split(",")[0] ?? ""

  if (siteName === "kohler-canada") {
    const address = addr.split(",") ?? ""
    data.city = address[1]?.trim() ?? ""
    data.state = address[2]?.trim() ?? ""
    data.zipcode = address[3]?.trim() ?? ""
  } else {
    const addrLine2 = addr.split(",")[1] ?? ""
    const splitAddress = addrLine2.split(" ")
    const len = splitAddress?.length ?? 0
    let addrLine1 = ""
    splitAddress.forEach((item, i) => {
      if (i < len - 2) addrLine1 = addrLine1 + item + " "
    })
    if (addrLine2) {
      data.city = addrLine1?.trim() ?? ""
      data.state = addrLine2.split(" ")[len - 2] ?? ""
      data.zipcode = addrLine2.split(" ")[len - 1] ?? ""
    }
  }
  return data
}

const getStateCode = (stateVal, countryVal, data) => {
  let country = {}
  if (data.find(obj => obj.code === countryVal)) {
    country = data.find(obj => obj.code === countryVal)
  } else {
    country = data.find(obj => obj.name === countryVal) ?? {}
  }
  if (country.states?.find(obj => obj.code === stateVal)) return stateVal
  const state = country.states?.find(obj => obj.name === stateVal) ?? {}
  return state.code ?? ""
}

const getCountryCode = (countryVal, data) => {
  if (data.find(obj => obj.code === countryVal)) return countryVal
  const country = data.find(obj => obj.name === countryVal) ?? {}
  return country.code ?? ""
}

const extractScriptsFromDOM = (doc, cb) => {
  const scripts = doc.getElementsByTagName("script")
  for (const script of scripts) {
    const scriptItem = {}
    const src = script.getAttribute("src") || ""
    const type = script.getAttribute("type") || ""
    const integrity = script.getAttribute("integrity") || ""
    const charset = script.getAttribute("charset") || ""
    const dataDomainScript = script.getAttribute("data-domain-script") || ""
    if (src) scriptItem.src = src
    if (charset) scriptItem.charset = charset
    if (dataDomainScript) scriptItem.dataDomainScript = dataDomainScript
    if (type) scriptItem.type = type
    if (integrity) scriptItem.integrity = integrity
    if (script.innerHTML) scriptItem.innerHTML = script.innerHTML
    if (process.env.NEXT_PUBLIC_CONSERNBANNER !== src) {
      cb(scriptItem)
    }
  }
}

const extractStylesFromDOM = (doc, cb) => {
  const styles = doc.getElementsByTagName("style")
  for (const style of styles) {
    if (style.innerHTML) cb(style.innerHTML)
  }
}
const extractLinksFromDOM = (doc, cb) => {
  const links = doc.getElementsByTagName("link")
  for (const link of links) {
    cb(link)
  }
}

const extractMetasFromDOM = (doc, cb) => {
  const metas = doc.getElementsByTagName("meta")
  for (const meta of metas) {
    cb(meta)
  }
}

const showNewBadge = shipmentDate => {
  if (shipmentDate) {
    const today = new Date()
    today.setMonth(today.getMonth() - 6)
    if (Date.parse(shipmentDate) > Date.parse(today)) {
      return true
    }
  }
  return false
}

const getCompareData = () => {
  const { compare } = store.getState()
  return compare
}

const isAuth = () => {
  const { isAuth } = store.getState()
  return isAuth
}

const getUser = () => {
  const { user } = store.getState()
  return user
}

const getFavouraties = () => {
  if (!isServer) {
    const { favorites } = store.getState()
    return favorites?.favorites
  }
  return []
}

const getDataLayerObj = datalayerObj => {
  store?.dispatch(setDataLayerObj(datalayerObj))
}
const getScriptsAndStyles = jsonData => {
  const { generic: { globalScripts = [], globalStyles = [] } = {} } =
    store.getState()

  const scriptsList = []
  const stylesList = []
  const dataRecursion = jsonData => {
    jsonData[":itemsOrder"] &&
      jsonData[":itemsOrder"].map((key, index) => {
        const jsonItem = jsonData[":items"][key]
        if (jsonItem[":type"]?.includes(COMPONENT_TYPES.EMBED)) {
          const doc = new DOMParser().parseFromString(
            jsonItem?.script,
            "text/html"
          )
          extractScriptsFromDOM(doc, item => {
            const isExit = globalScripts.some(
              el => JSON.stringify(el) === JSON.stringify(item)
            )
            !isExit && scriptsList.push(item)
          })
          extractStylesFromDOM(doc, item => {
            const isExit = globalStyles.some(el => el === item)
            !isExit && stylesList.push(item)
          })
        } else {
          return dataRecursion(jsonItem)
        }
      })
  }
  dataRecursion(jsonData)

  store?.dispatch(setGlobalScripts(scriptsList))
  store?.dispatch(setGlobalStyles(stylesList))
  return scriptsList
}

const hasComponentType = (data, type, cb) => {
  data[":itemsOrder"] &&
    data[":itemsOrder"].map(key => {
      const item = data[":items"][key]
      if (item[":type"].includes(type)) {
        cb && cb()
      } else {
        return hasComponentType(item, type, cb)
      }
    })
}

const isShowAnalyticsEventLoaded = () => {
  // this function is used to check if cmp:show event is already loaded or not to avoid duplicate show events
  if (window.adobeDataLayer) {
    let isLoaded = false
    window.adobeDataLayer.forEach(event => {
      if (event?.event === "cmp:show") {
        isLoaded = true
      }
    })
    return isLoaded
  }
  return false
}
const handleForOrderconfirmationCall = () => {
  if (document.isOrderConfirmation && !window?.pageObj?.transaction) {
    return false
  }
  return true
}

let pageLoadAnalyticsTimer
const addPageLoadAnalyticsEvent = () => {
  if (
    window.adobeDataLayer &&
    window.isShowAnalyticsEvent === true &&
    window.pageObj &&
    !isShowAnalyticsEventLoaded() &&
    window.datalayerTopLoaded &&
    handleForOrderconfirmationCall()
  ) {
    // we need to execute cmp:show event here as it is a first time page

    const page = window.pageObj
    const { category: { pageType = "", subCategoryID = "" } = {} } = page

    if (pageLoadAnalyticsTimer) {
      clearTimeout(pageLoadAnalyticsTimer)
      pageLoadAnalyticsTimer = undefined
    }

    if (!pageType) {
      pageLoadAnalyticsTimer = setTimeout(
        () => addPageLoadAnalyticsEvent(),
        500
      )
      return
    }
    if (
      pageType === "pdp" ||
      pageType === "search-results" ||
      pageType === "search" ||
      (pageType === "orders" && subCategoryID === "order details")
    ) {
      return
    }

    page.user = localStorage ? getUserAnalyticsObject() : {}

    const otherItems = {}
    if (page.component) {
      otherItems.component = page.component
      // delete page.component
    }
    if (page.eventInfo) {
      otherItems.eventInfo = page.eventInfo
      // delete page.eventInfo
    }
    if (page.productInfo) {
      otherItems.productInfo = page.productInfo
      // delete page.productInfo
    }
    window.adobeDataLayer.push({
      event: "cmp:show",
      page: page,
      ...otherItems,
    })

    window.isShowAnalyticsEvent = false
    delete window.isShowAnalyticsEvent
  }
}

const handleZipcodeSet = (zipCode, status, message) => {
  const eventInfo = {
    eventAction: "cart:landing page:order summary:update zip code",
    eventName: "cart:landing page:order summary:update zip code",
    eventMsg: message || "n/a",
    eventStatus: status,
    internalLinkName: "update",
    eventType: "navigation",
    internalLinkPosition: "cart",
    internalLinkType: "cart:" + zipCode,
    internalLinkZoneName: "cart:landing page:order summary",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}

  if (page.cart) {
    page.cart.zipcode = zipCode
  }

  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    page,
  })
}

const getAccessToken = () => {
  const { auth: { isAuth, access_token: accessToken = "" } = {} } =
    store.getState()
  let token = ""
  if (!isAuth && typeof window !== "undefined") {
    token = localStorage.getItem("okta-anonymous-access-token")
  }
  if (!token) token = accessToken
  if (token) {
    const tokenSplit = token.split(".")[1] || null
    if (tokenSplit) {
      const { gId, exp } = JSON.parse(Buffer.from(tokenSplit, "base64"))
      return { token, gId, exp }
    } else {
      return {}
    }
  }
  return { token }
}

const getAnonymousId = () => {
  let anonymousId = ""
  if (typeof window !== "undefined") {
    anonymousId = localStorage.getItem("anonymousId")
  }
  if (!anonymousId) anonymousId = store.getState()?.auth?.anonymousId
  return anonymousId
}

const isAuthenticated = async (ref, activeTab, isKPN) => {
  const { isAuth } = store.getState()?.auth
  const { general } = await getConfig()
  if (isAuth) {
    if (typeof ref === "function") {
      ref()
      return
    }
    if (isKPN) {
      const persona = getExactUserPersona()
      if (persona !== "TVS")
        return window.location.replace(
          getShortenedUrlwithGeneral(general.homepageUrl ?? "/", general)
        )
    }
    window.location.href = ref
  } else {
    window.loginCallback = ref
    store.dispatch(
      setAuthModalVisibility({
        show: true,
        active: !activeTab ? 0 : activeTab,
      })
    )
  }
}

const isGuestTokenValid = () => {
  const { isAuth } = store.getState()?.auth
  const { exp = "" } = getAccessToken()
  if (!exp) return false
  if (!isAuth) return moment().isBefore(moment.unix(exp).subtract(120, "s"))
  return true
}
const hourDifference = (date1, date2) => {
  let diff = (new Date(date2).getTime() - new Date(date1).getTime()) / 1000
  diff /= 60 * 60
  return Math.abs(Math.round(diff))
}

const getUserPersona = () => {
  if (!isServer) {
    if (process.env.NEXT_PUBLIC_ENABLE_ANY_PERSONA === "true") return "ANY"
    const { auth: { isAuth, user: { persona = [] } = {} } = {} } =
      store?.getState()
    if (isAuth) {
      const personaName = persona.find(e => e.includes(":")) ?? ""
      return personaName.split(":")[1] ?? "GST"
    }
  }
  if (process.env.NEXT_PUBLIC_ENABLE_ANY_PERSONA === "true") return "ANY"
  return "GST"
}

const getExactUserPersona = () => {
  if (!isServer) {
    const { auth: { isAuth, user: { persona = [] } = {} } = {} } =
      store?.getState()
    if (isAuth) {
      const personaName = persona.find(e => e.includes(":")) ?? ""

      return personaName.split(":")[1] ?? "GST"
    }
  }
  return "GST"
}

const createPersonaCookie = () => {
  const cookie = new Cookie()
  cookie.set("userPersona", getUserPersona(), { path: "/" })
}

const getAEMPreviewModeCookie = () => {
  const cookie = new Cookie()
  if (cookie.get("aem-author-preview-mode") && cookie.get("__token__")) {
    return true
  } else {
    return false
  }
}

const closePreview = () => {
  const cookie = new Cookie()
  sessionStorage.removeItem("aem-author-preview-mode")
  cookie.remove("aem-author-preview-mode", { path: "/" })
  cookie.remove("__token__", { path: "/" })
  localStorage.clear()
  sessionStorage.clear()
  window.location.reload()
}

const handleEnter = e => {
  if (e.keyCode === 13) {
    if (e.target.onclick && e.target.tagName !== "BUTTON") {
      e.stopPropagation()
      document.activeElement.click()
    }
  }
}

const setWindowProps = (obj, val) => {
  if (val) window[obj] = val
}

const arrayMove = (arr, fromIndex, toIndex) => {
  const newArr = [...arr]
  const element = newArr.splice(fromIndex, 1)[0]
  newArr.splice(toIndex, 0, element)
  return newArr
}

const getCurrency = async () => {
  const config = await getConfig()
  const { internationalization: { currencyCode = "USD" } = {} } = config
  return currencyCode
}
const getAnalyticsScript = analyticsScript => {
  if (analyticsScript) {
    store?.dispatch(setAnalyticsScript(analyticsScript))
  } else {
    store?.dispatch(setAnalyticsScript(""))
  }
}

const getPageLevelScriptsAndStyles = scriptsAndStyles => {
  const scriptsList = []
  const stylesList = []
  const metaList = []
  const linkList = []
  let bazaarVoice = null
  const dataRecursion = scriptsAndStyles => {
    const doc = new DOMParser().parseFromString(scriptsAndStyles, "text/html")
    extractScriptsFromDOM(doc, item => {
      const isBv = item?.src?.includes(BAZAAR_VOICE)
      if (isBv) {
        bazaarVoice = item
      } else {
        scriptsList.push(item)
      }
    })
    extractStylesFromDOM(doc, item => stylesList.push(item))
    extractMetasFromDOM(doc, item => metaList.push(item))
    extractLinksFromDOM(doc, item => linkList.push(item))
  }
  dataRecursion(scriptsAndStyles)
  const pageLevelDetails = {}
  pageLevelDetails["pageScripts"] = scriptsList
  pageLevelDetails["stylesList"] = stylesList
  pageLevelDetails["metaList"] = metaList
  pageLevelDetails["linkList"] = linkList
  return { pageLevelDetails, bazaarVoice }
}
const checkStock = (backOrder, category) => {
  return !(
    !category ||
    backOrder === true ||
    backOrder === "Yes" ||
    category === "Install Services" ||
    category === "Design Services"
  )
}
const checkCartStock = (cart = {}) => {
  const { lineItems = [] } = cart
  let valid = true
  lineItems
    .filter(item => !item.custom?.fields?.bundleParentId)
    .map(item => {
      const attributes = item.variant?.attributes ?? []
      const cartItem = {
        quantity: _get(item, "quantity", 0),
        backOrder: _get(item, "custom.fields.isBackOrderable", false),
        category: _get(
          attributes.find(attr => attr.name === "ProductLocalCategory"),
          "value",
          ""
        ),
      }
      if (valid && checkStock(cartItem.backOrder, cartItem.category)) {
        const channels = Object.values(
          _get(item, "variant.availability.channels", {})
        )
        if (channels.length > 0) {
          const availableQty = _get(channels[0], "availableQuantity", 0)
          if (availableQty < cartItem.quantity) {
            valid = false
          }
        }
      }
    })
  return valid
}

const getCartItemsAnalyticsData = async cartItems => {
  // first parameter should be cartItems
  const { general } = await getConfig()
  const data = { item: [], itemSkus: [] }

  cartItems.forEach(item => {
    const siteWideCoupanCode = []
    const productCouponCode = []
    const discountPercents = []
    let productCoupondiscount = 0
    let siteWideDiscount = 0

    if (item.discountsList?.length) {
      item.discountsList.forEach(discountItem => {
        if (discountItem.type === "sitewide") {
          // always apply sitewide discount first then remaining can be applied
          siteWideDiscount +=
            Number(item.unitPrice) * (discountItem.percent / 100)
          siteWideCoupanCode.push(discountItem.code?.toLowerCase())
        } else {
          // there can be multiple product coupon codes
          discountPercents.push(discountItem.percent)
          productCouponCode.push(discountItem.code)
        }
      })
      if (discountPercents.length) {
        let priceAfterDiscount = Number(item.unitPrice) - siteWideDiscount
        let temporaryCouponPrice
        for (const disc of discountPercents) {
          temporaryCouponPrice = Number(
            (priceAfterDiscount * (disc / 100)).toFixed(2)
          )
          priceAfterDiscount = priceAfterDiscount - temporaryCouponPrice
          productCoupondiscount += temporaryCouponPrice
        }
        productCoupondiscount = Number(productCoupondiscount.toFixed(2))
      }
    }
    const otherItem = {}
    if (item.productUpdated === true || item.productUpdated === false) {
      otherItem.productUpdated = item.productUpdated
    }

    const globalDiscount = siteWideDiscount
      ? siteWideDiscount
      : item?.discountAmount ?? 0
    const globalPromotionPrice = Number(item.unitPrice) - globalDiscount
    const itemType = getCartItemType(item.category, item.businessUnitName)
    const discountedPrice = Number(item?.discountPrice)
      ? Number(item?.discountPrice)
      : Number(item?.discountUnitPrice)

    const customerFacingSKUId = item.customerFacingSKU || item.sku
    data.itemSkus.push(item.sku)
    data.item.push({
      productInfo: {
        productBrand: general?.siteName?.toLowerCase(),
        productID: customerFacingSKUId,
        description: sanitizeTextForAnalytics(item.desc),
        productCategory: sanitizeTextForAnalytics(item.category),
        productName: sanitizeTextForAnalytics(
          item.name || item.desc || item.category
        ),
        productColor: sanitizeTextForAnalytics(item.color) || "n/a",
        productRoom:
          item?.isBundleParent || item?.isBundleChildItem
            ? sanitizeTextForAnalytics(item.category)
            : sanitizeTextForAnalytics(
                item.productRoom ||
                  (itemType === "service" ? itemType : "non-catalog")
              ),
        productSaleable: true,
        productStatus:
          !item.availableQty && item.backOrder
            ? "back order"
            : !checkStock(item.backOrder, item.category) ||
              item.availableQty >= item.quantity ||
              itemType === "service"
            ? "in stock"
            : "out of stock",
        productPartnerBuyNow: false,
        productBasePrice: Number(item.unitPrice),
        productCouponCode: productCouponCode.join("|") || "n/a",
        productCoupondiscount: productCoupondiscount || 0,
        productSuperSku: Boolean(item.superSku),
        productSalePrice: discountedPrice
          ? discountedPrice
          : Number(item.unitPrice),
        globalPromotionPrice: Number(globalPromotionPrice.toFixed(2)),
        defaultImageName: item.image,
        priceState:
          item.discountPercent > 0
            ? "percentOff|" + item.discountPercent
            : item.discountAmount > 0
            ? "priceOff|" + item.discountAmount
            : "regular price",
        productCollectionsName: sanitizeTextForAnalytics(
          item.productCollectionsName || item.category
        ),
        productFindingMethod: "n/a",
        productTileName: sanitizeTextForAnalytics(item.name || item.desc),
        quantity: item.quantity,
        globalOffer: siteWideCoupanCode.join("|") || "n/a",
        globalDiscount: Number(globalDiscount.toFixed(2)),
        itemType,
        isSubscription: false,
        isRecommended: false,
        frequency: "n/a",
        isSendNow: "n/a",
        ratings: "n/a",
        numberOfReviews: "n/a",
        pdpType:
          item?.isBundleParent || item?.isBundleChildItem
            ? "bundle"
            : "regular finished goods & service parts",
        ...otherItem,
      },
      quantity: item.quantity,
    })
  })
  return data
}

const getAssociatedServices = attr => {
  const productServices =
    attr.find(item => item.name === "ProductServices") ?? {}
  const { value = [] } = productServices
  return [...value]
}

const getFreightDiscountTotal = (codes = []) => {
  let total = 0
  codes.forEach(e => {
    if (FREIGHT_SHIPPING_TYPES.includes(e.name)) {
      total += Number(e.discount)
    }
  })
  return total
}

const getCartItemData = (data = {}) => {
  const { item = {}, CONFIG = {} } = data
  const { internationalization: { locale = "", currencySign = "$" } = {} } =
    CONFIG

  const {
    price: { discounted = {}, value: { centAmount: unitPrice = 0 } = {} } = {},
    discountedPrice = {},
    variant: { attributes = [], sku = "", images: variantImages = [] } = {},
    id = "",
    totalPrice: { centAmount: totalPrice = 0 } = {},
    productSlug = {},
    quantity = 0,
    custom: {
      fields: {
        isBundleChildItem = false,
        isBundleParent = false,
        bundleSku = "",
        parentBundleSku = "",
        bundleParentId = "",
        bundleParentProduct = {},
        bundleItemPrice: { centAmount: bundleUnitPrice = "" } = {},
        bundleTotalPrice: { centAmount: bundleTotalPrice = "" } = {},
      } = {},
    } = {},
  } = item

  const {
    obj: {
      masterData: {
        current: {
          name: names = {},
          description = {},
          slug: bundleSlug = {},
          masterVariant: {
            images = [],
            attributes: bundleAttributes = [],
          } = {},
        } = {},
      } = {},
    } = {},
  } = bundleParentProduct

  const { discount: { obj: { value: { money = [] } = {} } = {} } = {} } =
    discounted

  const bundleName = names[locale] ?? ""
  const bundleDesc = description[locale] ?? ""

  const regionBrandName = _get(
    attributes.find(attr => attr.name === "RegionBrandName"),
    "value",
    _get(item, `name[${locale}]`, "")
  )

  const productDesc = _get(
    attributes.find(attr => attr.name === "ProductDescriptionProductShort"),
    `value[${locale}]`,
    _get(
      attributes.find(attr => attr.name === "ProductDescriptionProduct"),
      "value",
      ""
    )
  )
  const color = _get(
    attributes.find(attr => attr.name === "SKUColorFinishName"),
    `value[${locale}]`,
    ""
  )

  const getShape = _get(
    attributes.find(attr => attr.name === "ProductShape"),
    `value`,
    ""
  )
  let shape
  if (getShape && getShape[0] && getShape[0][locale]) {
    shape = getShape[0][locale]
  }

  // Get line item details
  const productSection =
    _get(
      attributes.find(attr => attr.name === "ProductSection"),
      "value",
      null
    ) ?? []
  let productRoom
  if (productSection && productSection[0] && productSection[0][locale]) {
    productRoom = productSection[0][locale]
  }

  const getSize = _get(
    attributes.find(attr => attr.name === "ProductSize"),
    `value`,
    ""
  )
  let size
  if (getSize && getSize[0] && getSize[0][locale]) {
    size = getSize[0][locale]
  }

  let discountedPriceProductLevel = 0

  if (!_isEmpty(money)) {
    discountedPriceProductLevel = (
      Number(_get(money[0], "centAmount", 0)) / 100
    ).toFixed(2)
  }

  // Get line item price
  const {
    value: { centAmount: discountPriceValue = 0 } = {},
    includedDiscounts = [],
  } = discountedPrice

  let discountPrice = 0

  if (discounted) {
    const { value: { centAmount: dicCentAmount = 0 } = {} } = discounted
    discountPrice = (Number(dicCentAmount) / 100).toFixed(2)
  }
  if (discountedPrice) {
    discountPrice = (Number(discountPriceValue) / 100).toFixed(2)
  }

  const name = isBundleParent ? bundleName : regionBrandName
  const desc = isBundleParent ? bundleDesc : productDesc

  const discountedPricePerQuantity = (
    Number(_get(includedDiscounts[0]?.discountedAmount, "centAmount", 0)) / 100
  ).toFixed(2)

  const productSku = isBundleParent ? bundleSku : sku
  const image = isBundleParent ? images.at(0)?.url : variantImages.at(0)?.url

  const productUnitPrice = (
    Number(isBundleParent ? bundleUnitPrice : unitPrice) / 100
  ).toFixed(2)

  const productTotalPrice = (
    Number(isBundleParent ? bundleTotalPrice : totalPrice) / 100
  ).toFixed(2)
  const slug = isBundleParent ? bundleSlug[locale] : productSlug[locale]
  const category = getAttributeValue(
    isBundleParent ? bundleAttributes : attributes,
    "ProductLocalCategory"
  )

  return {
    isBundleParent,
    isBundleChildItem,
    id,
    bundleParentId,
    parentBundleSku,
    sku: productSku,
    customerFacingSKU: getAttributeValue(attributes, "CustomerFacingSKU"),
    productRoom,
    slug,
    quantity,
    image,
    category,
    brand: getAttributeValue(attributes, "CustomerFacingBrand"),
    associatedServices: getAssociatedServices(attributes),
    currencySign,
    name,
    desc,
    color,
    size,
    shape,
    unitPrice: productUnitPrice,
    totalPrice: productTotalPrice,
    discountUnitPrice: (
      Number(_get(item, "price.discounted.value.centAmount", 0)) / 100
    ).toFixed(2),
    discountPrice,
    discountedPricePerQuantity,
    discountedPriceProductLevel,
    isComboSku: attributes.some(attr => attr.name === "ComboSKU"),
    backOrder: _get(item, "custom.fields.isBackOrderable", false),
    businessUnitName: _get(
      attributes.find(attr => attr.name === "SPProductBusinessUnit"),
      "value",
      ""
    ),
    additionalData: item?.custom?.fields?.roomInfo
      ? _get(item, "custom.fields", "")
      : "",
    discountsList: [],
    productCollectionsName:
      getAttributeValue(attributes, "ProductBrandNameDisplay") ||
      getAttributeValue(attributes, "ProductBrandNameDisplay_t"),
    addedAt: _get(item, "addedAt", ""),
  }
}

const getBundleCombineData = items => {
  const bundleChildItems = items.filter(e => e.isBundleChildItem)
  const cartItems = items.filter(e => !e.isBundleChildItem)
  const data = []

  cartItems.forEach(e => {
    if (e.isBundleParent) {
      const childrens = bundleChildItems.filter(el => {
        return el.parentBundleSku === e.sku
      })
      const isOutOfStock = childrens.some(e => e.outOfStock)
      data.push({
        ...e,
        bundleLineItems: childrens,
        bundleItemsCount: childrens.length,
        outOfStock: isOutOfStock,
      })
    } else data.push(e)
  })
  return data
}

const getChildBundleData = items => {
  return items.filter(e => !e.isBundleParent)
}

export const calculateCartData = async (params = {}, isCart = false) => {
  const {
    cart: cartObject = {},
    i18n = {},
    noShippingItems: noShippingItemsProps = [],
    shippingItemstoCheckout = [],
    quantityUpdatedFlag,
  } = params

  const {
    shippingTotal: { centAmount: shippingTotal } = {},
    customerGroup: { id: custGroupId = "" } = {},
    customLineItems = [],
    billingAddress = {},
    promoCode = "",
    discountCodes = [],
    lineItems = [],
    subtotalWithoutDiscount: { centAmount: subtotalWithoutDiscount = 0 } = {},
    subtotalWithPromotion: { centAmount: subtotalWithPromotion = 0 } = {},
    totalPromo: { centAmount: totalPromo = 0 } = {},
    totalTax: { centAmount: totalTax = 0 } = {},
    estimatedTotal: { centAmount: estimatedTotalAmount = 0 } = {},
  } = cartObject

  const shippingItemsProps = shippingItemstoCheckout

  const CONFIG = await getConfig()

  const {
    internationalization: { locale = "", currencyCode: currency = "usd" } = {},
    general: { siteName = "", lwAppName = "" } = {},
  } = CONFIG

  const brandName = siteName?.toLowerCase()

  let voucherDiscount = 0
  let subtotal = 0
  let subtotalWithDiscount = 0
  let totalPromotions = 0
  let estimatedShipping = 0
  let estimatedTax = 0
  let estimatedTotal = 0
  let promoCodes = []
  let oldPromoCodes = []
  let oldDiscountCodes = []
  const cartItems = []
  const specFiles = []
  const outOfStockItems = []
  const noShippingItems = []

  let shippingDiscountsList = 0
  const shippingDiscountsNameList = []
  const voucherCode = []

  oldPromoCodes = promoCode
  oldDiscountCodes = discountCodes
  if (lineItems.length) {
    // Get discount code details
    if (promoCode?.length) {
      promoCode.forEach((item = {}) => {
        const {
          type = "",
          discountedAmount: { centAmount = 0 } = {},
          name,
        } = item
        let discountName
        if (item.code) {
          if (discountCodes?.length) {
            const matchedDiscountCode = discountCodes.find(
              dc => dc.discountCode?.obj?.code === item.code
            )
            const {
              discountCode: {
                obj: { name: disNames = {} },
              },
            } = matchedDiscountCode

            const localeName = disNames[locale]
            if (localeName) {
              discountName = localeName
            }
          }
          promoCodes.push({
            code: item.code,
            discount: (
              Number(_get(item, "discountedAmount.centAmount", 0)) / 100
            ).toFixed(2),
            id:
              discountCodes.find(
                code => code.discountCode.obj.code === item.code
              )?.discountCode?.id ?? "",
            type: item.type,
          })
        }
        // get product discounts
        else if (type === "product") {
          const proCode = promoCodes.find(p => p.type === "product")
          if (proCode) {
            const code = proCode
            code.discount = (
              Number(code.discount) +
              Number(centAmount) / 100
            ).toFixed(2)
          } else {
            promoCodes.unshift({
              code: i18n?.productDiscounts,
              code: "Product Discounts",
              discount: (Number(centAmount) / 100).toFixed(2),
              id: "",
              type: "product",
              name: discountName,
            })
          }
        }
        // get discounts without promo codes
        else {
          if (promoCodes.find(p => p.code === (name || discountName))) {
            const code = promoCodes.find(p => p.code === (name || discountName))
            code.discount = (
              Number(code.discount) +
              Number(centAmount) / 100
            ).toFixed(2)
          } else {
            centAmount &&
              promoCodes.unshift({
                code: item.name || discountName,
                discount: (Number(centAmount) / 100).toFixed(2),
                id: "",
                type: "no-code",
              })
          }
        }
      })
    }

    // Get shipping promotions
    if (customLineItems.length) {
      promoCodes = promoCodes.concat(
        await getShippingDiscountPromoCodes(cartObject)
      )
    }

    let lineItemsCopy = lineItems ?? []
    if (isCart) {
      lineItemsCopy =
        lineItems?.filter(item => {
          return !item.custom?.fields?.bundleParentId
        }) ?? []
    }

    const parentBundleItems = customLineItems.filter(
      item => item.custom?.fields.isBundleParent
    )
    lineItemsCopy = [...lineItemsCopy, ...parentBundleItems]

    lineItemsCopy.forEach((item, i) => {
      const {
        price: { discounted = {} } = {},
        discountedPrice = {},
        variant: { availability: { channels = [] } = {}, attributes = [] } = {},

        custom: {
          fields: {
            isBundleParent,
            bundleParentProduct: {
              obj: {
                masterData: {
                  current: {
                    masterVariant: {
                      availability: { channels: bundleChannels = {} } = {},
                    } = {},
                  } = {},
                } = {},
              } = {},
            } = {},
          } = {},
        } = {},
      } = item

      const {
        discount: {
          obj: { value: { permyriad, money = [], type = "" } = {} } = {},
        } = {},
      } = discounted

      cartItems[i] = getCartItemData({ item, CONFIG, custGroupId })

      if (quantityUpdatedFlag) {
        if (
          quantityUpdatedFlag === cartItems[i].customerFacingSKU?.toLowerCase()
        ) {
          cartItems[i].productUpdated = true
        } else {
          cartItems[i].productUpdated = false
        }
      }

      cartItems[i].superSku = cartItems[i].isComboSku

      // Get line item discounts
      if (discounted) {
        const isAbs = type === "absolute"
        const isRel = !isAbs
        if (isAbs) {
          cartItems[i].discountAmount = Number(money[0].centAmount / 100)
        } else {
          cartItems[i].discountPercent = Number(permyriad / 100)
          if (discounted.discount?.obj?.name[locale]) {
            // sitewide discount
            cartItems[i].discountsList?.push({
              type: "sitewide",
              isAbs,
              isRel,
              percent: isRel ? cartItems[i].discountPercent : 0,
              absolute: cartItems[i].discountAmount || 0,
              code: discounted.discount.obj.name[locale],
            })
          }
        }
      }

      const { includedDiscounts = [] } = discountedPrice

      if (includedDiscounts?.length) {
        includedDiscounts.forEach((includedDiscountItem = {}) => {
          const {
            discount: {
              obj: {
                name = {},
                value: { permyriad = 0, type = "", money = [] },
              } = {},
            } = {},
          } = includedDiscountItem
          const isAbs = type === "absolute"
          const isRel = type === "relative"
          if (isAbs) {
            cartItems[i].discountAmount = Number(money[0].centAmount / 100)
          } else if (isRel) {
            cartItems[i].discountPercent = Number(permyriad / 100)
          }

          if (isAbs || isRel) {
            const otherDiscount = name[locale]
            const lineItemDiscountName = promoCode?.find(
              p => p.type === "order" && p.name === otherDiscount
            )
            if (otherDiscount && lineItemDiscountName) {
              // capture discounts like buy 1 get 1 free. could be like installation service free on design service
              const matchedOrderDiscount = promoCodes.find(
                p => p.type === "no-code" && p.code === otherDiscount
              )
              if (matchedOrderDiscount) {
                // sitewide discount
                cartItems[i].discountsList?.push({
                  type: "sitewide",
                  percent: isRel ? cartItems[i].discountPercent : 0,
                  absolute: cartItems[i].discountAmount || 0,
                  isAbs,
                  isRel,
                  code: matchedOrderDiscount.code,
                })
              }
            }
            // find code from discountCode
            if (discountCodes?.length) {
              discountCodes.forEach((disCode = {}) => {
                const {
                  discountCode: { obj: { cartDiscounts = [], code = "" } } = {},
                } = disCode

                if (cartDiscounts?.length) {
                  cartDiscounts.forEach(discountItem => {
                    if (
                      discountItem.id === includedDiscountItem.discount.obj.id
                    ) {
                      // All coupon discounts
                      cartItems[i].discountsList?.push({
                        type: "cart",
                        isAbs,
                        isRel,
                        percent: cartItems[i].discountPercent || 0,
                        absolute: cartItems[i].discountAmount || 0,
                        code,
                      })
                    }
                  })
                }
              })
            }
          }
        })
      }

      const { availableQuantity = 0 } =
        channels[Object.keys(channels).at(0)] ?? {}

      const { availableQuantity: availableQuantityBundle = 0 } =
        bundleChannels[Object.keys(bundleChannels).at(0)] ?? {}

      cartItems[i].availableQty = isBundleParent
        ? availableQuantityBundle
        : availableQuantity

      // Check if item is out of stock
      if (checkStock(cartItems[i].backOrder, cartItems[i].category)) {
        if (cartItems[i].availableQty < cartItems[i].quantity) {
          cartItems[i].outOfStock = true
          outOfStockItems.push(cartItems[i])
        }
      }

      // get lead time if item is back ordered
      if (cartItems[i].backOrder === true) {
        cartItems[i].leadTime = _get(
          attributes.find(attr => attr.name === "RegionLeadTime"),
          "value",
          0
        )
      }
      // Check if all items are avilable for shipping
      const comboSku = _get(
        attributes.find(attr => attr.name === "ComboSKU"),
        "value"
      )
      const childSku = []
      comboSku?.forEach(e => {
        const sku = e.find(attr => attr.name === "sku")
        childSku.push(sku.value)
      })

      if (noShippingItemsProps?.find(e => childSku?.includes(e))) {
        cartItems[i].noShipping = true
        noShippingItems.push(cartItems[i])
      }

      if (noShippingItemsProps?.includes(item.variant?.sku)) {
        cartItems[i].noShipping = true
        noShippingItems.push(cartItems[i])
      } else if (shippingItemsProps) {
        const { warningMessage = "", parentLineItemId = "" } =
          shippingItemsProps?.find(
            shippingItem => shippingItem.lineItemId === item.id
          ) ?? {}

        if (warningMessage) {
          if (parentLineItemId) {
            const parentIndex = cartItems.findIndex(
              c => c.id === parentLineItemId
            )
            if (parentIndex >= 0 && !cartItems[parentIndex].noShipping) {
              cartItems[parentIndex].noShipping = true
              noShippingItems.push(cartItems[parentIndex])
            }
          } else {
            cartItems[i].noShipping = true
            noShippingItems.push(cartItems[i])
          }
        }
      }

      // Get spec files for all line items
      const productResources = _get(
        attributes.find(attr => attr.name === "ProductResource"),
        "value",
        []
      )
      if (productResources.length) {
        const resource =
          productResources.find(arr => arr[1].value === "SpecPDFFileName") ?? []
        const resourceUrl =
          resource.find(obj => obj.name === "ResourceFullWebURL") ?? {}
        const specFileUrl = resourceUrl.value ?? ""
        if (specFileUrl) specFiles.push(specFileUrl)
      }
    })

    // Get order summary price amounts
    subtotal = Number(subtotalWithoutDiscount / 100).toFixed(2)
    subtotalWithDiscount = Number(subtotalWithPromotion / 100).toFixed(2)
    totalPromotions = Number(totalPromo / 100).toFixed(2)
    estimatedTax = Number(totalTax / 100).toFixed(2)

    estimatedShipping = Number((shippingTotal ?? 0) / 100).toFixed(2)
    estimatedTotal = Number(estimatedTotalAmount / 100).toFixed(2)

    // checking shipping related discounts and adding it to price object
    promoCodes.forEach(promo => {
      if (promo.type === "shipping" || promo.type === "no-code") {
        shippingDiscountsList += Number(promo.discount)
        shippingDiscountsNameList.push(promo.code)
      }
    })
    // Add shipping promotions to total promotions
    promoCodes
      .filter(item => item.type === "shipping")
      .forEach(item => {
        estimatedShipping = Number(estimatedShipping) + Number(item.discount)
      })
  }

  promoCodes.forEach(itm => {
    voucherCode.push(itm.code)
    voucherDiscount += Number(itm.discount)
  })
  const page = window.pageObj ?? {}
  const { category: { subCategoryName = "" } = {} } = page
  const cartItemsData = getBundleCombineData(cartItems)
  let analyticsCartItems = []

  if (subCategoryName === "pdp") {
    analyticsCartItems = await getCartItemsAnalyticsData(cartItemsData)
  } else {
    const cartItemsAllData = getChildBundleData(cartItems)
    analyticsCartItems = await getCartItemsAnalyticsData(cartItemsAllData)
  }

  const cart = {
    cartID: cartObject?.id,
    cartNumber: cartObject?.key,
    price: {
      basePrice: subtotal || 0,
      voucherCode: voucherCode.length ? voucherCode.join("|") : "n/a",
      voucherDiscount: voucherDiscount ? Number(voucherDiscount.toFixed(2)) : 0,
      globalOffer: shippingDiscountsNameList
        ? shippingDiscountsNameList.join("|") || "n/a"
        : "n/a",
      globalDiscount: shippingDiscountsList
        ? Number(shippingDiscountsList.toFixed(2))
        : 0,
      currency: currency.toLowerCase(),
      taxRate: estimatedTax || "n/a",
      shipping: estimatedShipping ? Number(estimatedShipping) : "n/a",
      priceWithTax: estimatedTotal || 0,
      cartTotal: subtotal || 0,
      couponCode: "n/a",
      couponDiscount: 0,
      orderCouponCode: "n/a",
      orderCouponDiscount: 0,
      shippingMethod: "ground",
    },
    ...analyticsCartItems,
  }

  cartItemsData.forEach(e => {
    const matchedItem = analyticsCartItems?.item?.find(
      i => i.productInfo.productID === e.customerFacingSKU
    )
    if (matchedItem) {
      e.customProductInfo = sanitizeDOMString(JSON.stringify(matchedItem))
    }
  })

  cartItemsData.sort((prev, curr) => {
    return new Date(curr.addedAt) - new Date(prev.addedAt)
  })

  sanitizeObject(cartItemsData)
  sanitizeObject(cart)

  const freightDiscountTotal = getFreightDiscountTotal(promoCodes)

  const shippingAllTotal = getShippingAllTotal(customLineItems, locale)

  return {
    oldPromoCodes,
    oldDiscountCodes,
    cartItems: cartItemsData,
    subtotal,
    subtotalWithDiscount,
    totalPromotions,
    estimatedTax,
    estimatedShipping,
    estimatedTotal,
    shippingTotal: shippingTotal / 100,
    promoCodes,
    specFiles,
    outOfStockItems,
    noShippingItems,
    shippingDiscountsNameList,
    shippingDiscountsList,
    cart,
    freightDiscountTotal,
    shippingAllTotal,
    brandName,
    billingAddress,
    lwAppName,
  }
}

const getShippingDiscountPromoCodes = async cartObject => {
  const locale = await getConfig().then(config =>
    _get(config, "internationalization.locale", "")
  )

  const { customLineItems = [], discountCodes = [] } = cartObject
  const promoCodes = []

  customLineItems.forEach((item = {}) => {
    const { discountedPrice: { includedDiscounts = [] } = {} } = item

    includedDiscounts.forEach((disc = {}) => {
      const {
        discountedAmount: { centAmount: discountedAmountCent = 0 } = {},
        discount: {
          obj: { requiresDiscountCode, id = "", name: objName = {} } = {},
        } = {},
      } = disc

      const discountedAmount = Number(discountedAmountCent) / 100

      const discountName = objName[locale] ?? ""
      const name = item?.name?.[locale] ?? ""

      // get shipping promotions with codes
      if (requiresDiscountCode) {
        const dicountIdObj =
          discountCodes?.find(
            disc => disc.discountCode?.obj?.cartDiscounts[0]?.id === id
          ) ?? {}

        const discountId = dicountIdObj.discountCode?.id ?? ""
        if (!promoCodes.find(code => code.id === discountId)) {
          promoCodes.push({
            code: discountName,
            discount: discountedAmount.toFixed(2),
            id: discountId,
            type: "shipping",
            name,
          })
        } else {
          const index = promoCodes.findIndex(code => code.id === discountId)
          promoCodes[index].discount = (
            Number(promoCodes[index].discount) + discountedAmount
          ).toFixed(2)
        }
      }
      // get shipping promotions without codes
      else {
        if (!promoCodes.find(p => p.code === name)) {
          promoCodes.push({
            code: discountName,
            discount: discountedAmount.toFixed(2),
            id: "",
            type: "shipping",
            name,
          })
        } else {
          const index = promoCodes.findIndex(p => p.code === name)
          promoCodes[index].discount = (
            Number(promoCodes[index].discount) + discountedAmount
          ).toFixed(2)
        }
      }
    })
  })

  return promoCodes
}

const getCartItemType = (category = "", businessUnitName = "") => {
  if (!category && !businessUnitName) {
    return "n/a"
  }
  category = category.toLowerCase()
  businessUnitName = businessUnitName.toLowerCase()
  if (
    category === "install services" ||
    category === "design services" ||
    category === "services"
  ) {
    return "service"
  }
  if (category === "samples") {
    return "sample"
  }
  if (
    category === "parts" ||
    category === "service parts" ||
    businessUnitName.indexOf("serviceparts") !== -1 ||
    businessUnitName === "service parts"
  ) {
    return "part"
  }
  return "product"
}

const sanitizeDOMString = property =>
  typeof property === "string" ? DOMPurify.sanitize(property) : property

export const decodeHtmlCharacters = str => {
  return str
    .replace(/&amp;/g, "&")
    .replace(/&#8217;/g, "'")
    .replace(/&nbsp;/g, " ")
}

export const sanitizeAndDecodeHtml = str => {
  const decodeVal = decodeHtmlCharacters(str)
  return sanitizeInnerHtml(decodeVal)
}

const updateObject = (objRef, objKey) => {
  const copyObject = { ...objRef }
  copyObject[objKey] = sanitizeDOMString(copyObject[objKey])
  return copyObject
}

export const sanitizeObject = obj => {
  if (typeof obj === "object" && obj !== null) {
    Object.keys(obj).forEach(objKey => {
      if (typeof obj[objKey] === "string") {
        return updateObject(obj, objKey)
      }
      return sanitizeObject(obj[objKey])
    })
  }
  return obj
}
const handleZipDownload = async (files, filename, success, err) => {
  for (let i = 0; i < files.length; i++) {
    if (!files[i].includes("http")) {
      files.splice(i, i + 1)
      i--
    }
  }

  try {
    const payload = { files: files }
    if (filename) {
      payload.downloadFileName = filename
    } else {
      const crypto = window.crypto || window.msCrypto
      const array = new Uint32Array(1)
      const rand = crypto.getRandomValues(array)[0]

      const { general } = await getConfig()
      const brandName = general?.siteName ?? ""
      const randomId = Math.floor(rand * 10000000).toString(16)
      payload.downloadFileName = `${brandName}-${randomId}`
    }
    const { payload: res } = await store?.dispatch(zipDownload(payload))
    if (res) {
      let downloadFileName = filename + ".zip"

      if (!filename) {
        const disposition = contentDisposition.parse(
          res.headers["content-disposition"]
        )
        downloadFileName = disposition.parameters.filename
      }
      fileDownload(res.data, downloadFileName)
      if (success) success()
    }
  } catch (ex) {
    // temp
    // log.error(ex)
    if (err) err()
  }
}

const isEmbeddedElement = script => {
  if (script) {
    const doc = new DOMParser().parseFromString(script, "text/html")
    if (doc.body.innerHTML) {
      return true
    }
  }
  return false
}

const isEmbeddedStyles = script => {
  const stylesList = []
  if (script) {
    const doc = new DOMParser().parseFromString(script, "text/html")

    extractStylesFromDOM(doc, item => stylesList.push(item))
  }
  const resultVal = { styles: stylesList }
  return resultVal
}

const getSiteWideModal = (e, url) => {
  if (url.indexOf("http") === 0) {
    e.preventDefault()
    window.handleShowModal(url)
  }
}

const getAttributeValue = (attributes, name) => {
  const { value = "" } = attributes.find(attr => attr.name === name) ?? {}
  return value
}

const createIdFromString = text => {
  const testData = text ?? ""
  testData
    .replace(SANITIZE_TEXT_REGEX, "")
    .toLowerCase()
    .replace(/ /g, "-")
    .trim()
  return testData
}

const capitalizeFirstName = firstname => {
  firstname = firstname?.charAt(0).toUpperCase() + firstname?.slice(1)
  return firstname
}

const setFocus = id => {
  document.getElementById(id)?.focus()
}

const preventBodyScroll = status => {
  if (status) {
    document.body.classList.add("scroll-lock", "modal-open")
    document
      .getElementsByTagName("html")[0]
      .classList.add("scroll-lock", "modal-open")
  } else {
    document.body.classList.remove("scroll-lock", "modal-open")
    document
      .getElementsByTagName("html")[0]
      .classList.remove("scroll-lock", "modal-open")
  }
}
const preventBodyScrollTab = status => {
  if (status) {
    document.body.classList.add("gbh-of-scroll")
  } else {
    document.body.classList.remove("gbh-of-scroll")
  }
}

const sanitizeText = text => text?.replace(/<\/?[^>]+(>|$)/g, "")

// Sort
const getSortFromUrl = () => {
  const url = new URL(location.href)
  const appliedSort = url.searchParams.get("sort") ?? ""
  return appliedSort.includes("%2B")
    ? appliedSort.replaceAll("%2B", "+")
    : appliedSort.replaceAll(" ", "+")
}

const getSelectedSort = (text, query) => {
  switch (query) {
    case "bestSeller_l+asc":
      return { name: text.bestSellers, query: `bestSeller_l+asc` }
    case "price+desc":
      return { name: text.priceDesc, query: `price+desc` }
    case "price+asc":
      return { name: text.priceAsc, query: `price+asc` }
    case "CollapseSort_s+asc":
    case "NonCollapseSort_s+asc":
      return { name: text.atoz, query }
    case "CollapseSort_s+desc":
    case "NonCollapseSort_s+desc":
      return { name: text.ztoa, query }
    case "productName_s+asc":
      return { name: text.atoz, query }
    case "productName_s+desc":
      return { name: text.ztoa, query }
    case "title_s+asc":
    case "cardTitle_s+asc":
      return { name: text.atoz, query }
    case "title_s+desc":
    case "cardTitle_s+desc":
      return { name: text.ztoa, query }
    case "new":
      return { name: text.newToOld, query }
    case "old":
      return { name: text.oldToNew, query }
    // Temp Fix AToZ & ZToA
    case "atoz":
      return { name: text.AToZ, query }
    case "ztoa":
      return { name: text.ZToA, query }
  }
  return { name: text.featured, query: `` }
}

const replaceSearchUrl = (searchTerm, param) => {
  const url = new URL(window.location.href)
  let searchUrl = url.search
  const searchArray = searchUrl.split("&")
  const index = searchUrl
    .split("&")
    .findIndex(param => param.includes(searchTerm))
  if (param === "" || param === undefined) {
    searchArray.splice(index, 1)
    const urlIndex = searchArray.findIndex(searchParam =>
      searchParam.includes("?")
    )
    searchUrl =
      searchArray.length !== 0
        ? urlIndex === -1
          ? `?${searchArray.join("&")}`
          : searchArray.join("&")
        : searchArray.join("&")
  } else if (index !== -1) {
    searchArray.splice(index, 1, `${searchTerm}=${param}`)
    if (!index) {
      searchArray[index] = `?${searchArray[index]}`
    }
    searchUrl = searchArray.join("&")
  } else {
    searchUrl = searchUrl
      ? searchUrl + `&${searchTerm}=${param}`
      : `?${searchTerm}=${param}`
  }
  history.replaceState(
    window.location.pathname,
    document.title,
    url.pathname + searchUrl
  )
}

const getPriceSortQuery = () => {
  const persona = getUserPersona()
  return `priceList.${persona}.finalPrice_d`
}

const getSortItemBasedOnURL = (text, defaultItem) => {
  const urlParams = window.location.search
  let queryVal = ""
  if (urlParams) {
    const urlQuery = urlParams.replace("?", "").split("&")
    urlQuery.forEach((query, i) => {
      const isBack = query.split("=")[0] === "back"
      if (isBack) {
        sessionStorage.plpScrollSku = query.split("=")[1]
      }
      queryVal += isBack ? "" : `${i > 0 ? "&" : ""}${query}`
    })
    window.history.replaceState(
      window.location.pathname,
      document.title,
      queryVal.length > 0 ? `?${queryVal.toString()}` : window.location.pathname
    )
  }

  const query = getSortFromUrl()
  const selectedSortItem = query
    ? getSelectedSort(text, query)
    : defaultItem || null
  return selectedSortItem
}

// *****
const filterBackEndJson = json => {
  const newJson = {}
  for (const k in json) {
    if (k !== "allowedComponents") {
      if (
        typeof json[k] === "object" &&
        !Array.isArray(json[k]) &&
        json[k] !== null
      ) {
        newJson[k] = filterBackEndJson(json[k])
      } else {
        newJson[k] = json[k]
      }
    }
  }
  return newJson
}
// Filter
const formatFacetName = (name, filter, facetLabels) => {
  let val =
    facetLabels && facetLabels[name?.replace(/^\*{2}/, "")]
      ? facetLabels[name?.replace(/^\*{2}/, "")]
      : name
          ?.replace(/\./g, "/")
          ?.replace(/_/g, " ")
          ?.replace(/^\*{2}/, "")
  if (filter) val += ` - ${filter}`
  return val
}

const formatPriceRange = (filter, isAnnsacksPlp = false) => {
  const values = filter.match(/\d+/g)
  if (Array.isArray(values)) {
    return isAnnsacksPlp
      ? `$${Number(values[0])?.toLocaleString()} - $${Number(
          values[1]
        )?.toLocaleString()}`
      : `$${values[0]} - $${values[1]}`
  } else {
    return null
  }
}

const resetFilterUrl = () => {
  let searches = location.search.split("&")
  searches = searches.filter(
    item => queryParamsToPersist.filter(param => item.includes(param)).length
  )
  const url = searches.join("&")
  window.history.replaceState(
    {},
    document.title,
    window.location.pathname + (url.includes("?") ? url : url && `?${url}`)
  )
}

const getFacetsFromUrl = (marketParamValues = {}) => {
  const currFilters = []
  const url = new URL(window.location.href)
  const urlParams = url.searchParams.get("facets")
  const aMarkettingParameters = marketParamValues?.marketParamValues

  if (urlParams) {
    urlParams
      .replace("?", "")
      .split(",")
      .forEach(filter => {
        if (
          filter.split(":")[0] !== "title" &&
          filter.split(":")[0] !== "id" &&
          filter.split(":")[0] !== "keyword" &&
          filter.split(":")[0] !== "compare" &&
          filter.split(":")[0] !== "back" &&
          filter.split(":")[0] !== "sort" &&
          filter.split(":")[0] !== "activeTab" &&
          filter.split(":")[0] !== "tab" &&
          filter.split(":")[0] !== "skuSearch" &&
          filter.split(":")[0] !== "service" &&
          !aMarkettingParameters?.includes(filter.split(":")[0])
        ) {
          const facet = decodeURIComponent(filter.split(":")[0])
          if (facet === "wcmmode") return
          const decode = decodeURIComponent(filter)
          const values = decode && decode.split(":")[1].split("|")
          let display = decode && decode.split(":")[1].split("|")
          display = display.map(val => val.replace(FILTER_PARAM_REGEX, " "))
          const filterName = decode.split(":")[0]

          display = display.map(d => d.replace(/\\/g, ""))

          // format price range filter
          if (filterName === "Price_Range") {
            display = display.map(range => formatPriceRange(range))
          }

          // Check if the filter attribute already exists
          let checkFacet = false
          currFilters.forEach(item => {
            checkFacet = Object.values(item).includes(facet)
          })

          // Add a new filter or add values to existing filter
          if (!checkFacet) {
            currFilters.push({
              facet: facet,
              value: values,
              display: display,
            })
          } else {
            currFilters.forEach(fc => {
              if (facet === fc.facet) {
                fc.value.push(values)
                fc.display.push(display)
              }
            })
          }
        }
      })
  }

  return currFilters.filter(currFilter => currFilter.facet !== "sort")
}
const getParamsFromUrl = async () => {
  const urlParams = window.location.search
  const decode = decodeURIComponent(urlParams)
  const value = decode && decode.split("=")[1].split(",")
  return value
}

const retainSearchQueryParams = type => {
  const search = window.location.search
  let srpTabDetails = {}
  if (!search.includes("tab=")) {
    srpTabDetails = {
      [type]: search,
    }
  } else {
    if (search.includes(`tab=${type}`)) {
      srpTabDetails = {
        [type]: search,
      }
    }
  }

  if (sessionStorage.getItem("SRPTabDetails")) {
    srpTabDetails = JSON.parse(sessionStorage.getItem("SRPTabDetails"))
    srpTabDetails[type] = window.location.search
  }
  sessionStorage.setItem("SRPTabDetails", JSON.stringify(srpTabDetails))
}
const removeSearchQueryParams = () => {
  sessionStorage.removeItem("SRPTabDetails")
}

const checkIfUserExists = email => apim.post("/customer/lookup", { email })

const removeHtmlWithoutHttps = str => {
  const htmlPositions = []
  const htmlPattern = /.html/g
  let match
  let formattedString = str
  while ((match = htmlPattern.exec(str) !== null)) {
    htmlPositions.push(match.index)
  }
  if (htmlPositions.length) {
    for (let i = 0; i < htmlPositions.length; i++) {
      const htmlPostion = formattedString.indexOf(".html")
      const herfStartingPosition = formattedString.lastIndexOf('"', htmlPostion)
      const subStr = formattedString.substring(
        herfStartingPosition + 1,
        herfStartingPosition + 6
      )
      if (!subStr.includes("http")) {
        formattedString =
          formattedString.slice(0, htmlPostion) +
          formattedString.slice(htmlPostion + 5)
      }
    }
  }
  return formattedString
}

const getShortenedUrl = async uUrl => {
  const { general } = await getConfig()
  if (general) {
    const { shortendPagePath } = general
    const shortUrl = shortendPagePath ?? ""
    const htmlRemovedUrl = removeHtmlWithoutHttps(uUrl)
    const shortUrlRegEx = new RegExp(shortUrl, "g")

    return `${htmlRemovedUrl?.replace(shortUrlRegEx, "")}`
  }
  return ""
}

export const getShortenedUrlPath = uUrl => {
  const { generic: { globalConfig: { general = {} } = {} } = {} } =
    store?.getState() || {}

  const { shortendPagePath = "" } = general
  if (shortendPagePath) {
    const htmlRemovedUrl = removeHtmlWithoutHttps(uUrl)
    const shortUrlRegEx = new RegExp(shortendPagePath, "g")
    return `${htmlRemovedUrl?.replace(shortUrlRegEx, "")}`
  }
  return ""
}

export const getAuthShortenedUrl = async name => {
  const { general = {} } = await getConfig()
  const url = general[name]
  if (general) {
    const { shortendPagePath } = general
    const shortUrl = shortendPagePath ?? ""
    const htmlRegExp = new RegExp(".html", "g")
    const htmlRemovedUrl = `${url?.replace(htmlRegExp, "")}`
    const shortUrlRegEx = new RegExp(shortUrl, "g")

    return `${htmlRemovedUrl?.replace(shortUrlRegEx, "")}`
  }
  return ""
}

const getShortenedUrlwithGeneral = (uUrl, general) => {
  if (general) {
    const { shortendPagePath } = general
    const shortUrl = shortendPagePath ?? ""
    const htmlRemovedUrl = removeHtmlWithoutHttps(uUrl)
    const shortUrlRegEx = new RegExp(shortUrl, "g")

    return `${htmlRemovedUrl?.replace(shortUrlRegEx, "")}`
  }
  return ""
}

const getFacetField = (facetFields, facetQuery) => {
  return Array.isArray(facetFields)
    ? facetFields.find(f => f === facetQuery)
    : facetFields === facetQuery
}

const getAllFilterQueries = (
  componentProps,
  fusion = {},
  marketParamValues = {}
) => {
  let currFilters = []
  const urlFilters = getFacetsFromUrl(marketParamValues)
  const facetFields = fusion["facet.field"] ?? []
  const facetLabels = fusion?.facet_labels ?? {}
  if (urlFilters.length > 0) {
    urlFilters.forEach(f => {
      let facetQuery =
        f.facet === "Price_Range"
          ? "Price_Range"
          : Object.keys(facetLabels).find(
              key => facetLabels[key] === f.facet.replace(/_/g, " ")
            )
      const isValidFacet = getFacetField(facetFields, facetQuery)
      if (isValidFacet) facetQuery = `**${facetQuery}`
      if (facetQuery) {
        f.facet = facetQuery
      }
    })
  }

  // Set pre-selected filters
  if (
    urlFilters?.length === 0 &&
    componentProps?.attributeName &&
    componentProps?.attributevalue &&
    componentProps?.attributeName !== "" &&
    componentProps?.attributevalue !== ""
  ) {
    let facetQuery = componentProps?.attributeName
    const isValidFacet = getFacetField(facetFields, facetQuery)
    if (isValidFacet) {
      facetQuery = `**${facetQuery}`
    }
    currFilters.push({
      facet: facetQuery,
      value: [componentProps?.attributevalue.replace(/"/g, '\\"')],
      display: [componentProps?.attributevalue],
    })
  }
  currFilters = [...currFilters, ...urlFilters]
  return currFilters
}

const convertStringToBoolean = str => {
  if (typeof str === "string") {
    if (str.toLowerCase() === "true") {
      return true
    } else if (str.toLowerCase() === "false") {
      return false
    } else {
      return false
    }
  } else if (typeof str === "boolean") {
    return str
  } else {
    return false
  }
}
const resetUrl = () => {
  const url = new URL(window.location.href)
  const searchUrl = url.search
  const searchArray = searchUrl.split("&")
  const searchedUrl = searchArray.length ? searchArray[0] : ""
  history.replaceState(
    window.location.pathname,
    document.title,
    url.pathname + searchedUrl
  )
}

const formatMultiSelectFacets = data => {
  const facets = Object.entries(data.facet_counts?.facet_fields ?? {}).map(
    fields => ({ [fields[0]]: fields[1] })
  )
  let facetField = data?.fusion["facet.field"]
  if (facetField) {
    if (typeof facetField === "string") facetField = [facetField]
    facetField.forEach(item => {
      facets.forEach((filter, index) => {
        if (filter[item]) {
          const obj = {
            [`**${item}`]: filter[item],
          }
          facets.splice(index, 1, obj)
        }
      })
    })
  }
  const result = Object.assign({}, ...facets)
  return result
}

const addADALink = (ele, params) => {
  const a = ele?.getElementsByTagName("a")
  if (a && a.length) {
    for (let i = 0; i < a.length; i++) {
      const anchor = a[i]
      if (!anchor.hasAttribute("aria-label")) {
        const innerHtml = anchor.innerHTML
        anchor.setAttribute(
          "aria-label",
          anchor.getAttribute("target") === "_blank"
            ? params.translate(
                "kf.pdp.label.aria.dangerouslyLinkADABlankPage",
                { innerHtml }
              )
            : params.translate("kf.pdp.label.aria.dangerouslyLinkADA", {
                innerHtml,
              })
        )
      }
    }
  }
}
const removeAndCleanLink = link => {
  return link
    ? link
        ?.split(process.env.NEXT_PUBLIC_INTERNALLINKPATH)
        .join("")
        .replace(".html", "")
    : link
}

const mapStateToCountryHandler = (countryData = [], stateData = []) => {
  const data = []
  countryData.forEach(country => {
    data.push({
      code: country.value,
      name: country.text,
      states: [],
    })
  })
  stateData.forEach(state => {
    const countryCode = state.value?.split(":")[1]
    data
      .find(country => country.code === countryCode)
      ?.states.push({
        code: state.value?.split(":")[0],
        name: state.text,
      })
  })
  return data
}

export const addToRecentlyViewed = id => {
  const { general } = getConfig()
  const max = general?.recentlyViewedMax ?? 10
  let items = localStorage.getItem("recently-viewed")
    ? JSON.parse(localStorage.getItem("recently-viewed"))
    : []
  if (id) {
    items = items.filter(i => i !== id)
    items.unshift(id)
    const newItems = items.slice(0, max)
    localStorage.setItem("recently-viewed", JSON.stringify(newItems))
  }
}

const getModelSuggestionSkuListFromData = data => {
  const skuList = []
  data.map(variants => {
    const labelList =
      variants?.customerFacingSKUList_ss ?? _get(variants, "variantList.sku_ss")
    if (Array.isArray(labelList)) {
      labelList.forEach(sku => skuList.push(sku))
    } else if (labelList) {
      skuList.push(labelList)
    }
  })
  return skuList
}

const handleModelNumberSuggestion = resp => {
  const {
    response: { numFound, docs },
  } = resp
  if (numFound < 1) {
    return {
      suggestions: [],
      invalidModelNumber: numFound === 0 ? true : false,
    }
  } else {
    const skuList = getModelSuggestionSkuListFromData(docs) ?? []
    return {
      suggestions: skuList,
    }
  }
}

export const closeModalAnalytics = productInfo => {
  setTimeout(() => {
    const modalCloseButtons = document.getElementsByClassName("bv-mbox-close")
    if (!window.modalCloseEventLoaded && modalCloseButtons?.length) {
      // this means review contents are loaded
      const modalCloseButton = modalCloseButtons[0]
      const clickEvent = function () {
        const { adobeDataLayer: { getState } = {} } = window
        const page = (getState && getState("page")) || {}
        const eventInfo = {
          eventAction: "pdp:modal:write a review:close",
          eventName: "pdp:modal:write a review:close",
          eventType: "navigation",
          eventMsg: "n/a",
          eventStatus: "n/a",
          internalLinkName: "close",
          internalLinkPosition: "pdp",
          internalLinkType: "pdp:close",
          internalLinkZoneName: "pdp:modal:write a review",
          internalLinkURL: "n/a",
          clickInternalLinks: "true",
        }
        window.adobeDataLayer.push({
          event: "cmp:click",
          eventInfo,
          page,
          productInfo: productInfo ? JSON.parse(productInfo).productInfo : null,
        })
        modalCloseButton.removeEventListener("click", clickEvent)
        delete window.checker
        delete window.modalCloseEventLoaded
      }
      modalCloseButton.addEventListener("click", clickEvent)
      window.modalCloseEventLoaded = true
    } else if (
      !window.modalCloseEventLoaded &&
      (!window.checker || window.checker < 10)
    ) {
      window.checker = (window.checker ?? 0) + 1
      closeModalAnalytics(productInfo)
    }
  }, 2000)
}
const sanitizeTextArticle = text => {
  return text.replace(/<\/?[^>]+(>|$)/g, "").replace(/(\r\n|\n|\r)/gm, "")
}

export const cleanText = (text = "") => text.replace(/<\/?[^>]+(>|$)/g, "")

const appendPresetToUrl = (
  assetAccountName,
  swatchUrl,
  assetId,
  width,
  presetConfigs = {},
  isReel
) => {
  const {
    Desktop,
    MobileLandscape = "",
    MobilePortrait = "",
    LandscapeCategory,
  } = presetConfigs

  let preset = ""
  if (width <= 768) {
    if (width <= 425) {
      if (MobilePortrait) {
        preset = MobilePortrait
      }
    } else {
      if (MobileLandscape) {
        preset = MobileLandscape
      }
    }
  } else {
    preset = isReel ? Desktop : MobileLandscape
  }
  return `${swatchUrl}${LandscapeCategory}?$product_src=is{${assetAccountName}/${assetId}}&${preset}`
}

const getPresetThumbnailUrl = (
  assetAccountName,
  swatchUrl,
  assetId,
  presetConfigs
) => {
  const { Thumbnail, LandscapeCategory } = presetConfigs
  return `${swatchUrl}${LandscapeCategory}?$product_src=is{${assetAccountName}/${assetId}}&${Thumbnail}`
}
const checkIsFavorite = items => {
  const page = window.location.origin + window.location.pathname
  const checkLink = item => {
    let link = {}
    try {
      link = new URL(item.custom?.fields?.link)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
    return link.origin + link.pathname === page ? true : false
  }
  const isFavorite = el => {
    // check if asset url is added to favorites
    if (
      el.dataset?.asset &&
      items.find(item => item.custom?.fields?.link === el.dataset.url)
    ) {
      return items.find(item => item.custom?.fields?.link === el.dataset.url).id
    }
    if (
      el.dataset?.asset &&
      items.find(
        item =>
          item.custom?.fields?.link === window.location.origin + el.dataset.url
      )
    ) {
      return items.find(
        item =>
          item.custom?.fields?.link === window.location.origin + el.dataset.url
      ).id
    }
    // check if current page url is added to favorites
    if (!el.dataset?.asset && items.find(item => checkLink(item))) {
      return items.find(item => checkLink(item)).id
    }
    return false
  }
  const favElements = [
    ...document.getElementsByClassName("fav-trigger"),
    ...document.getElementsByClassName("fav-video-trigger"),
  ]
  favElements.forEach(elem => {
    const id = isFavorite(elem)
    if (id) {
      elem.classList.add("favorite-filled")
      elem.dataset.id = id
    }
  })
}

const sanitizeProductName = name => name.replace(/®|™/g, "")

/**
 *
 * @param {Boolean} isEnable
 */

const toggeleBodyScroll = isEnable => {
  if (isEnable) {
    document.body.classList.add("remove-scroll")
  } else {
    document.body.classList.remove("remove-scroll")
  }
}
const toggeleBodyClass = isEnable => {
  if (isEnable) {
    document.body.classList.add("add-body-class")
  } else {
    document.body.classList.remove("add-body-class")
  }
}

const toggeleBodyClassAfterLogin = isEnable => {
  if (isEnable) {
    document.body.classList.add("add-body-class-after-login")
  } else {
    document.body.classList.remove("add-body-class-after-login")
  }
}

const formatOfflineRegisterPropsData = root => {
  const result = {}
  if (root && root[":items"]) {
    const handleItems = items => {
      for (const [key, value] of Object.entries(items)) {
        if (key === "register_offline_pro") {
          Object.assign(result, value)
        } else if (value && value[":items"]) {
          handleItems(value[":items"])
        }
      }
    }
    handleItems(root[":items"])
  }
  return result
}

const formatOnlineRegisterPropsData = root => {
  const result = {}
  if (root && root[":items"]) {
    const handleItems = items => {
      for (const [key, value] of Object.entries(items)) {
        if (key === "register_online_prod") {
          Object.assign(result, value)
        } else if (value && value[":items"]) {
          handleItems(value[":items"])
        }
      }
    }
    handleItems(root[":items"])
  }
  return result
}

const nbspSanitizeInnerHtml = property => {
  const sanitizedHtml = DOMPurify.sanitize(property).replace(/&nbsp;/g, " ")
  const parsedHtml = htmlParse(sanitizedHtml)
  return parsedHtml
}

const getDefaultProfileAddress = profile => {
  const {
    firstName = "",
    lastName = "",
    defaultShippingAddressId = "",
    addresses = [],
  } = profile
  const prefilledCustomerDetails = {
    firstName: { value: firstName },
    lastName: { value: lastName },
  }
  if (addresses.length) {
    const defaultAddress = addresses.find(
      item => item.id === defaultShippingAddressId
    )
    if (!_isEmpty(defaultAddress)) {
      const { city, postalCode, state, streetName } = defaultAddress
      prefilledCustomerDetails["city"] = { value: city }
      prefilledCustomerDetails["address"] = { value: streetName }
      prefilledCustomerDetails["zipCode"] = { value: postalCode }
      prefilledCustomerDetails["state"] = { value: state }
    }
  }
  return prefilledCustomerDetails
}

const getPathFromUrl = url => {
  return url.split(/[?#]/)[0]
}

const validatePhone = (phone = "") => {
  phone = phone.replace("(", "")
  phone = phone.replace(")", "")
  phone = phone.replace(" ", "")
  phone = phone.replace("-", "")
  if (/^\d+$/.test(phone) && phone.length > 9) return true
  return false
}

const getCityList = (url, brand, searchTerm) => {
  return apim.get(url, {
    params: {
      $format: "json",
      brand,
      $select: "Locality",
      $filter: `StartsWith(Locality,'${searchTerm}') eq true`,
    },
  })
}

const storeFilterData = filterData => {
  const storeFinalData = {}
  delete filterData["jcr:primaryType"]
  const filterKeys = Object.keys(filterData)
  filterKeys.forEach(element => {
    delete filterData[element]["jcr:primaryType"]
    const categoryKeys = filterData[element]["categoryName"]
    const categoryList = filterData[element]["list"]
    delete categoryList["jcr:primaryType"]
    const categoryListValues = Object?.values(filterData[element]["list"])
    const filteredCategoryValues = Object?.values(categoryListValues)
    const finalFacets = filteredCategoryValues.map(x => ({
      label: x.displayName,
      query: x.bingName,
    }))
    storeFinalData[categoryKeys] = finalFacets
  })
  return storeFinalData
}

const checkAlphanumeric = value => {
  const pattern = /^[A-Za-z0-9 ]+$/
  if (pattern.test(value)) {
    return true
  }
  return false
}
const checkAlphabetOnly = value => {
  return ALPHABET_WITH_SPACE_REGEX.test(value)
}
const getRandom = () => {
  if (!isServer) {
    const crypto = window.crypto || window.msCrypto
    const array = new Uint32Array(1)
    return crypto.getRandomValues(array)[0]
  } else {
    return parseInt(new Date().getTime())
  }
}

const getAnalyticsUrl = url =>
  (url.indexOf("http") === -1 ? window.location.origin : "") + url

const getPageInfo = () => {
  const { adobeDataLayer: { getState } = {} } = window
  return (getState && getState("page")) || {}
}
const formatDate = async date => {
  const {
    internationalization: { locale: currentLocale },
  } = await getConfig()
  const d = new Date(date)
  const options = { year: "numeric", month: "long", day: "numeric" }
  return d.toLocaleDateString(currentLocale, options)
}
const downloadArticle = async (
  url = "",
  e = {},
  successCallback = () => {},
  downloadSuccess = "",
  downloadFailure = ""
) => {
  const { target: { classList: targetClassList = {} } = {} } = e
  try {
    targetClassList?.add(DOWNLOAD_SPINNER)

    const { status, data, headers } = await apim.get(
      `catalog/pdf-file-download?page=${url}`,
      { responseType: "blob" }
    )
    if (status === 200) {
      let filenameVal
      if (headers["content-disposition"]) {
        const { parameters: { filename = "" } = {} } = contentDisposition.parse(
          headers["content-disposition"]
        )
        filenameVal = filename
      } else {
        filenameVal = "zip"
      }

      fileDownload(data, filenameVal)
      typeof successCallback === "function" && successCallback()
      store.dispatch(
        showToast({
          message: downloadSuccess,
          isVisible: true,
        })
      )
      targetClassList?.remove(DOWNLOAD_SPINNER)
    } else {
      store.dispatch(
        showToast({
          message: downloadFailure,
          isVisible: true,
        })
      )
      targetClassList?.remove(DOWNLOAD_SPINNER)
    }
  } catch (ex) {
    targetClassList?.remove(DOWNLOAD_SPINNER)
    store.dispatch(
      showToast({
        message: downloadFailure,
        isVisible: true,
      })
    )
  }
}
const getAppliedSortPressKit = (i18n, query) => {
  switch (query) {
    case "navTitle_s+asc":
      return i18n.sortByAsc
    case "navTitle_s+desc":
      return i18n.sortByDesc
    case "pressReleaseDate_dt+desc":
      return i18n.sortByNewest
    case "pressReleaseDate_dt+asc":
      return i18n.sortByOldest
  }
  return null
}

// Function will scroll to a particular element
const scrollToElement = element => {
  element.current.scrollIntoView({
    behavior: "smooth",
    block: "end",
    inline: "nearest",
  })
}

// function to get offsetTop
const getOffsetTop = element => {
  let offsetTop = 0
  while (element) {
    offsetTop += element.offsetTop
    element = element.offsetParent
  }
  return offsetTop
}

// Function to check part page or not
const isPartsPage = url => {
  const urls = url?.split("?")
  let isPart = false
  if (urls?.length && urls[1]) {
    isPart = url?.includes("type=part")
  } else {
    isPart = url?.includes("/support/")
  }
  return isPart
}

const getPdpUrlParams = () => {
  const url = new URL(window.location.href)
  const searchParams = url.searchParams
  const skuNumber = searchParams.get("skuNumber")
  const skuId = searchParams.get("skuId")
  if (skuNumber || skuId) {
    skuNumber
      ? searchParams.set("skuNumber", skuNumber)
      : searchParams.set("skuId", skuId)
    const query = "?" + searchParams.toString()
    return query
  }
  return null
}

const getUpSellDataByRecursion = data => {
  let upSellContainerData = {}
  if (data[":itemsOrder"]?.length) {
    let isContainerMatched = false
    let item = {}
    for (let i = 0; i < data[":itemsOrder"].length; i++) {
      const key = data[":itemsOrder"][i]
      item = data[":items"][key]
      if (isUpSellContainer(item)) {
        upSellContainerData = item
        isContainerMatched = true
        break
      }
      if (item?.hasOwnProperty(":itemsOrder") && item[":itemsOrder"]?.length) {
        for (let j = 0; j < item[":itemsOrder"].length; j++) {
          const innerKey = item[":itemsOrder"][j]
          if (item[":items"][innerKey].hasOwnProperty(":itemsOrder")) {
            item = item[":items"][innerKey]
            if (isUpSellContainer(item)) {
              upSellContainerData = item
              isContainerMatched = true
              break
            }
          }
        }
        if (isContainerMatched) break
      }
    }
    if (!isContainerMatched) return getUpSellDataByRecursion(item)
    return upSellContainerData
  }
  return upSellContainerData
}

const getUpSellAuthData = (data = {}, isRequireToLookupFullData = false) => {
  let upSellAuthData = {}
  if (_isEmpty(data)) return {}
  let upSellContainerData = data
  if (isRequireToLookupFullData) {
    upSellContainerData = getUpSellDataByRecursion(data)
  }
  const {
    searchLabelUpSell,
    zipUpdateLabelUpSell,
    zipChangeLabelUpSell,
    zipLabelUpSell,
    userTitleUpSell,
  } = upSellContainerData
  const headerAuthData = {
    searchLabelUpSell,
    zipUpdateLabelUpSell,
    zipChangeLabelUpSell,
    zipLabelUpSell,
    userTitleUpSell,
  }
  const productCard =
    upSellContainerData[":items"]?.["container"]?.[":items"]?.[
      "productcard_v4"
    ] ?? {}
  const serviceData = upSellContainerData[":items"]?.["up_sell_services"] ?? {}
  const itemsTabs = upSellContainerData[":items"]?.["up_sell_tabs"]?.[":items"]
  const tabs = []
  const tabsData = {}
  if (!_isEmpty(itemsTabs)) {
    for (const tabKey in itemsTabs) {
      if (Object.prototype.hasOwnProperty.call(itemsTabs, tabKey)) {
        const panelTitle = itemsTabs[tabKey]["cq:panelTitle"].toLowerCase()
        tabs.push(panelTitle)
        tabsData[panelTitle] = itemsTabs[tabKey][":items"]["up_sell_services"]
      }
    }
  }
  const upSellServiceData = tabsData.length ? tabsData["services"] : serviceData
  upSellAuthData = {
    headerAuthData,
    tabs,
    tabsData,
    productCard,
    upSellServiceData,
  }
  return upSellAuthData
}
const isUrlIncludeHttp = url => {
  if (url) {
    return url.indexOf("http") !== -1
  } else {
    return false
  }
}

export const getInternalLinkTypeForFindStore = (
  store,
  city,
  state,
  name,
  isLocationPage = false
) => {
  const storeName = sanitizeTextForAnalytics(store)
  const cityName = sanitizeTextForAnalytics(city)
  const stateName = sanitizeTextForAnalytics(state)
  const micrositeName = sanitizeTextForAnalytics(name)
  if (isLocationPage) return `${storeName}:${cityName}:${stateName}`
  return `${micrositeName}:${cityName}:${stateName}`
}

const getProcessedProductInfoBasedOnLineItems = async lineItems => {
  const config = await getConfig()
  const locale = _get(config, "internationalization.locale", "")
  const brandName = _get(config, "general.siteName", "")?.toLowerCase()
  const currency = _get(config, "internationalization.currencyCode", "usd")
  const currencySign = _get(config, "internationalization.currencySign", "$")
  const { general: { lwAppName = "" } = {} } = config ?? {}

  const productItems = lineItems.map(item => {
    let discountPrice = item?.discountPrice ?? 0
    if (item.price?.discounted) {
      discountPrice = (
        Number(_get(item.price.discounted, "value.centAmount", 0)) / 100
      ).toFixed(2)
    }

    if (item.discountedPrice) {
      discountPrice = (
        Number(_get(item.discountedPrice, "value.centAmount", 0)) / 100
      ).toFixed(2)
    }
    const discountedPricePerQuantity = (
      Number(
        _get(
          item?.discountedPrice?.includedDiscounts[0]?.discountedAmount,
          "centAmount",
          0
        )
      ) / 100
    ).toFixed(2)
    const attributes = item.variant?.attributes ?? []
    const productSection = _get(
      attributes.find(attr => attr.name === "ProductSection"),
      "value",
      null
    )
    let productRoom = ""
    if (productSection && productSection[0] && productSection[0][locale]) {
      productRoom = productSection[0][locale]
    }
    return {
      id: _get(item, "id", ""),
      sku: _get(item, "variant.sku", ""),
      customerFacingSKU: getAttributeValue(attributes, "CustomerFacingSKU"),
      productRoom: productRoom,
      slug: _get(item, `productSlug[${locale}]`, ""),
      quantity: _get(item, "quantity", 0),
      image: _get(item, "variant.images[0].url", ""),
      category: getAttributeValue(attributes, "ProductLocalCategory"),
      brand: getAttributeValue(attributes, "CustomerFacingBrand"),
      associatedServices: getAssociatedServices(attributes),
      currencySign,
      name: _get(
        attributes.find(attr => attr.name === "RegionBrandName"),
        "value",
        _get(item, `name[${locale}]`, "")
      ),
      desc: _get(
        attributes.find(attr => attr.name === "ProductDescriptionProductShort"),
        `value[${locale}]`,
        _get(
          attributes.find(attr => attr.name === "ProductDescriptionProduct"),
          "value",
          ""
        )
      ),
      color: _get(
        attributes.find(attr => attr.name === "SKUColorFinishName"),
        `value[${locale}]`,
        ""
      ),
      unitPrice:
        typeof item.unitPrice === "string"
          ? item.unitPrice
          : (Number(_get(item, "price.value.centAmount", 0)) / 100).toFixed(2),
      totalPrice:
        typeof item.totalPrice === "string"
          ? item.totalPrice
          : (Number(_get(item, "totalPrice.centAmount", 0)) / 100).toFixed(2),
      discountPrice: discountPrice,
      discountedPricePerQuantity: discountedPricePerQuantity,
      isComboSku: attributes.find(attr => attr.name === "ComboSKU")
        ? true
        : false,
      backOrder: _get(item, "custom.fields.isBackOrderable", false),
      businessUnitName: _get(
        attributes.find(attr => attr.name === "SPProductBusinessUnit"),
        "value",
        ""
      ),
      additionalData:
        brandName === "annsacks" && item?.custom?.fields?.roomInfo
          ? _get(item, "custom.fields", "")
          : "",
      discountsList: [],
      productCollectionsName:
        getAttributeValue(attributes, "ProductBrandNameDisplay") ||
        getAttributeValue(attributes, "ProductBrandNameDisplay_t"),
      addedAt: _get(item, "addedAt", ""),
    }
  })
  return {
    productItems,
    brandName,
    currency,
    lwAppName,
  }
}
const jsonStringEscape = jsonString => {
  return jsonString.replace(/\n|\t|\r|\&|\'/g, m => {
    switch (m) {
      case "\n":
      case "\r":
      case "\t":
      case "\b":
      case "\f":
        return ""
      case "&":
        return "&amp;"
      case "'":
        return "&#8217;"
      default:
        return
    }
  })
}

export const replaceAndFunction = str => {
  return str?.replace("&", "%26")
}

export const getBadgeValue = (key, arry) => {
  const badgeInfo = arry.find(item => item.map.Key === key)
  const {
    map: { Key = "", Values: { myArrayList: badgeValue = [] } = {} } = {},
  } = badgeInfo
  return {
    key: Key,
    value: badgeValue[0] ?? "",
  }
}

export const pushAnalyticsObjToDataLayer = (
  eventInfo = {},
  event = "cmp:click",
  modifiedPageObj = {},
  customDataLayer = {}
) => {
  const { adobeDataLayer: { getState } = {} } = window
  let page = (getState && getState("page")) || {}

  if (!_isEmpty(modifiedPageObj)) {
    page = modifiedPageObj
  }

  const datalayerObj = { event, eventInfo, page }

  if (!_isEmpty(customDataLayer)) {
    for (const key in customDataLayer) {
      if (Object.hasOwn(customDataLayer, key)) {
        datalayerObj[key] = customDataLayer[key]
      }
    }
  }

  window.adobeDataLayer.push(datalayerObj)
}

export const removeMavenoidChat = () => {
  return document
    .querySelector("mavenoid-assistant")
    ?.classList?.add("mavenoid-remove")
}
export const addMavenoidChat = () => {
  return document
    .querySelector("mavenoid-assistant")
    ?.classList?.remove("mavenoid-remove")
}

export const hasNumber = str => {
  return /\d/.test(str)
}

export const generateCanonicalUrl = (url, locale, host) => {
  if (url) {
    if (url?.includes(".json") && typeof window !== "undefined") {
      return window.location.href
    }
    const canonicalUrl = url.split("?")[0] ?? url
    if (locale === "fr") {
      return `${host?.substring(0, host.length - 2)}${locale}${canonicalUrl}`
    } else {
      return `${host}${canonicalUrl}`
    }
  }
  return ""
}

export const updateAnalyticPageUrl = (data, url) => {
  if (data) {
    const parsedData = JSON.parse(data)
    parsedData.pageInfo.pageUrl = url
    const analyticsData = JSON.stringify(parsedData)
    return analyticsData
  }
  return ""
}
export const sortParameterURL = () => {
  const url = new URL(window.location.href)
  const appliedSort = url.searchParams.get("sort") ?? ""
  return appliedSort?.split(" ").join("+")
}
// Below util helper (removePathandHtml) is created especially when shortened URL is needed from getserversideprops function.
export const removePathandHtml = (url = "") => {
  if (url) {
    const { shortendPagePath = "" } = redirectsData ?? {}
    const htmlRemovedUrl = url?.replace(".html", "")
    const pathRemovedUrl = htmlRemovedUrl?.replace(shortendPagePath, "")
    return pathRemovedUrl
  }
  return null
}

export const performRedirect = (url = "", locale = "") => {
  let redirectUrl
  let redirectType
  const urlWithLocale = `/${locale}${url}`
  Object.keys(redirectsData).forEach(key => {
    if (redirectsData[key][url]) {
      redirectUrl = redirectsData[key][url]
      redirectType = key
    } else if (redirectsData[key][urlWithLocale]) {
      redirectUrl = redirectsData[key][urlWithLocale]
      redirectType = key
    }
  })

  if (redirectUrl) {
    redirectUrl = removePathandHtml(redirectUrl)
    return { redirectUrl, url, redirectType }
  }
  return null
}

export const getPureText = str => {
  return str.replace(PURE_TEXT_REGEX, "")
}

export const isPriceZero = price => price === "0.00" || price === 0

const toastMsg = msg =>
  store.dispatch(
    showToast({
      message: msg,
      isVisible: true,
    })
  )

export const addMediaToFavorites = (
  type = "",
  name = "",
  url = "",
  successMsg = "",
  errMsg = "",
  internalLinkType = "",
  sharedZoneName = "",
  videoName = "",
  videoId = ""
) => {
  isAuthenticated(() =>
    store.dispatch(
      addMedia({
        type,
        name,
        url,
        errMsg,
        internalLinkType,
        sharedZoneName,
        videoName,
        videoId,
        successCallback: () => toastMsg(successMsg),
      })
    )
  )
}

export const removeFavorites = (ids, favEle, removemsg) => {
  isAuthenticated(() =>
    store
      .dispatch(removeItems(ids))
      .unwrap()
      .then(() => {
        favEle.current.removeAttribute("data-id")
        favEle.current.classList.remove("favorite-filled")
        toastMsg(removemsg)
      })
  )
}

export const organizeData = (
  data = [],
  name = [],
  placeHolder = "",
  type = "",
  idVal = ""
) =>
  data.map((item, i) => {
    let obj = {
      title: name.length
        ? name[i]
        : `${placeHolder} ${data.length > 1 ? i + 1 : ""}`,
    }
    if (idVal) {
      obj.id = idVal + "-" + i
    }
    if (type === "PART") {
      obj = { ...obj, id: item }
    } else {
      obj = { ...obj, type, url: item }
    }
    return obj
  })

export const getAnalyticsFileName = file => {
  const { actualName = "", title = "", type = "" } = file
  const fileName =
    actualName || sanitizeTextForAnalytics(`${title?.trim()}.${type}`)
  return fileName
}
export const getPresetUrl = (
  width = 0,
  swatchUrl = "",
  image = "",
  presetConfigs = {},
  scene7AccountName = ""
) => {
  const {
    Desktop = "",
    MobileLandscape = "",
    MobilePortrait = "",
    PotraitCategory = "",
  } = presetConfigs
  const assetId = image.split("/").pop()
  const scene7Url = swatchUrl?.replace("PAWEB/", "")
  let preset = Desktop
  if (width >= 1024) {
    preset = Desktop
  } else if (width < 1024 && width >= 768) {
    preset = MobileLandscape
  } else if (width < 768) {
    preset = MobilePortrait
  }
  return `${scene7Url}${scene7AccountName}/${PotraitCategory}?$product_src=is{${scene7AccountName}/${assetId}}&${preset}`
}
export const recalculateCartRoomInfo = (lineItemId, product) => {
  const actions = []
  if (product?.roomInfo) {
    actions.push(
      {
        action: "setLineItemCustomField",
        lineItemId: lineItemId,
        name: "roomInfo",
        value: [
          '{"productType":"' +
            product?.productType +
            '","totalNeeded":"' +
            product?.totalNeeded +
            '","totalUserNeeded":"' +
            product?.totalUserNeeded +
            '","totalSFUserNeeded":"' +
            product?.totalSFUserNeeded +
            '","totalArea":"' +
            product?.totalArea +
            '","soldByBox":"' +
            product?.soldByBox +
            '","soldByUom":"' +
            product?.soldByUom +
            '"}',
        ],
      },
      {
        action: "setLineItemCustomField",
        lineItemId: lineItemId,
        name: "customQuantity",
        value: product?.soldByBox
          ? Number(product?.customQuantity * product?.soldByBox)
          : product?.customQuantity,
      },
      {
        action: "setLineItemCustomField",
        lineItemId: lineItemId,
        name: "totalUOM",
        value: `${product?.totalArea}`,
      },
      {
        action: "setLineItemCustomField",
        lineItemId: lineItemId,
        name: "overage",
        value: product?.overage,
      },
      {
        action: "changeLineItemQuantity",
        lineItemId: lineItemId,
        quantity: product?.customQuantity,
      }
    )
  } else {
    actions.push(
      {
        action: "setLineItemCustomField",
        lineItemId: lineItemId,
        name: "customQuantity",
        value: product?.quantity,
      },
      {
        action: "changeLineItemQuantity",
        lineItemId: lineItemId,
        quantity: product?.quantity,
      }
    )
  }
  try {
    apim
      .post(`/cart/v2/items?expand=calculation`, {
        actions: actions,
      })
      .then(response => {
        store.dispatch(setCart(response.data))
        store.dispatch(setCartCount(getQuantityCount(response.data.lineItems)))
        store.dispatch(setQtyLoading(false))
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err)
  }
}

const checkIfImageExists = (url, callback) => {
  const img = new Image()
  img.src = url

  if (img.complete) {
    callback(true)
  } else {
    img.onload = () => {
      callback(true)
    }
    img.onerror = () => {
      callback(false)
    }
  }
}

const getLinkFromHref = htmlString => {
  let link = htmlString.match(/href="([^"]*)"/)
  link = link ? link[1] : ""

  if (typeof window !== "undefined" && link.indexOf("https") === -1) {
    link = window.location.href + link
  }
  return link
}

const isCanadaSite = siteName =>
  siteName && siteName.trim().toLocaleLowerCase() === "kohler-canada"

const getProductStatus = (
  discontinued = false,
  backorder = "",
  inStock = true
) => {
  const productStatus = discontinued
    ? "discontinued"
    : backorder === "Yes"
    ? "backorder"
    : inStock
    ? "in stock"
    : "out of stock"
  return productStatus
}

const getLangCode = config => {
  const locale = config?.internationalization?.locale?.toLowerCase()
  const languageName = process.env.NEXT_PUBLIC_LANGUAGENAME?.toLowerCase()
  const result = locale?.includes(languageName)
    ? LOCALE_ENGLISH.toLowerCase()
    : locale
  return result
}

const getIsNameDuplicate = (value, listNameValidationList) => {
  return listNameValidationList
    .map(name => name?.toUpperCase())
    .includes(value?.toUpperCase())
}

const getUserCreatedLists = (response, locale) => {
  const lists = []
  const listNames = []
  response
    .sort((a, b) => new Date(b?.lastModifiedAt) - new Date(a?.lastModifiedAt))
    .forEach(list => {
      lists.push({
        name: list?.name[locale],
        id: list?.id,
        version: list?.version,
      })
      listNames.push(list?.name[locale])
    })
  return { lists: lists, listNames: listNames }
}

export {
  getRandom,
  removeAndCleanLink,
  getFavouraties,
  getUser,
  isAuth,
  getCompareData,
  showNewBadge,
  getCurrentSkuCouponDiscounts,
  isColorFacet,
  getMyLocation,
  getMyLocationByUserIP,
  getUserIP,
  getLocationByCookies,
  sanitizeInnerHtml,
  sanitizeDOMString,
  formatPhoneNumber,
  validateField,
  getShortenedUrl,
  navigationType,
  addToRecentSearch,
  getParamFromUrl,
  smoothScroll,
  getClienIP,
  isProtectedUrl,
  updateHeader,
  shaEncrypt,
  caesarEncrypt,
  caesarDecrypt,
  getPasswordRegExp,
  getUserAnalyticsObject,
  isElementExcluded,
  clearAuthToken,
  clearAnonymousToken,
  sanitizeUrl,
  revalidateTime,
  getStateCode,
  getCountryCode,
  destructureAddress,
  extractScriptsFromDOM,
  extractStylesFromDOM,
  getUserPersona,
  getExactUserPersona,
  sanitizeTextForAnalytics,
  getPDPUrl,
  getSwatchCount,
  isAuthenticated,
  getScriptsAndStyles,
  isShowAnalyticsEventLoaded,
  getAccessToken,
  getAnonymousId,
  isGuestTokenValid,
  handleEnter,
  setWindowProps,
  arrayMove,
  getPageLevelScriptsAndStyles,
  getAnalyticsScript,
  getCurrency,
  checkCartStock,
  pdpTypeFormat,
  getCartItemsAnalyticsData,
  handleZipDownload,
  isEmbeddedElement,
  getAttributeValue,
  getSiteWideModal,
  getDataLayerObj,
  addPageLoadAnalyticsEvent,
  handleZipcodeSet,
  getCartItemType,
  createIdFromString,
  capitalizeFirstName,
  setFocus,
  preventBodyScroll,
  checkStock,
  getPDPUrlWithConfig,
  getShippingDiscountPromoCodes,
  sanitizeText,
  getSortFromUrl,
  getSelectedSort,
  replaceSearchUrl,
  filterBackEndJson,
  createPersonaCookie,
  formatFacetName,
  formatPriceRange,
  resetFilterUrl,
  retainSearchQueryParams,
  getPriceSortQuery,
  getPDPUrlForProductTile,
  hasComponentType,
  getFacetsFromUrl,
  checkIfUserExists,
  convertStringToBoolean,
  getAllFilterQueries,
  hourDifference,
  formatMultiSelectFacets,
  addADALink,
  getParamsFromUrl,
  resetUrl,
  getSortItemBasedOnURL,
  mapStateToCountryHandler,
  getModelSuggestionSkuListFromData,
  handleModelNumberSuggestion,
  appendPresetToUrl,
  getPresetThumbnailUrl,
  sanitizeTextArticle,
  checkIsFavorite,
  getShortenedUrlwithGeneral,
  sanitizeProductName,
  toggeleBodyScroll,
  toggeleBodyClass,
  toggeleBodyClassAfterLogin,
  formatOfflineRegisterPropsData,
  removeSearchQueryParams,
  nbspSanitizeInnerHtml,
  getDefaultProfileAddress,
  getAEMPreviewModeCookie,
  closePreview,
  getPathFromUrl,
  validatePhone,
  getCityList,
  storeFilterData,
  checkAlphanumeric,
  preventBodyScrollTab,
  formatOnlineRegisterPropsData,
  getAnalyticsUrl,
  getPageInfo,
  formatDate,
  downloadArticle,
  getAppliedSortPressKit,
  scrollToElement,
  getUpSellAuthData,
  isPartsPage,
  getPdpUrlParams,
  isEmbeddedStyles,
  getProcessedProductInfoBasedOnLineItems,
  isUrlIncludeHttp,
  getOffsetTop,
  jsonStringEscape,
  debounce,
  checkAlphabetOnly,
  customSanitizeInnerHtml,
  checkIfImageExists,
  getLinkFromHref,
  isCanadaSite,
  getProductStatus,
  getLangCode,
  getIsNameDuplicate,
  getUserCreatedLists,
}

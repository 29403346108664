import { apim } from "@/constants/api"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"
import { store } from "@/store"
import {
  favoritesEndPoint,
  addFavoritesEndPoint,
  createFavoritesEndPoint,
  removeFavoritesEndPoint,
  FAVORITES_LIST_END_POINT,
  FAVORITES_LIST_LIMIT,
} from "@/constants/index"
import { checkIsFavorite, getUserPersona } from "@/utils/helper"
import { addDataLayerFav } from "@/utils/favouritesHelper"
import { getConfig } from "@/constants/config"
import { getSessionIdObjWithEpoc, getUserListLineItems } from "@/utils/ApiUtil"
const initialState = {
  favorites: {},
  status: "",
  fullFavoriteData: {},
  isLoginFavouriteCallback: { isCallBack: false, callBackAssetID: "" },
  saveToListLineItems: {},
  userLists: [],
}
const shoppingListName = "Favorites"

export const getFavorites = createAsyncThunk("getFavorites", async () => {
  const config = await getConfig()
  const locale = config?.internationalization?.locale ?? "en-US"
  const response = await apim.get(`${favoritesEndPoint}?lang=${locale}`)
  const { results } = response?.data ?? { results: [] }
  if (results.length > 0) {
    const getVersion = Math.max(...results.map(i => i.version))
    const favoritesData = results.filter(
      item => item.name[locale] === "Favorites" && item.version === getVersion
    )
    const { textLineItems = [] } = favoritesData[0]
    if (textLineItems.length > 0) checkIsFavorite(textLineItems)
    if (favoritesData.length) {
      return favoritesData[0]
    }
  }
  return {}
})
export const getMyAccountListsWithLineItems = createAsyncThunk(
  "getMyAccountListsWithLineItems",
  async () => {
    const config = await getConfig()
    const locale = config?.internationalization?.locale ?? "en-US"
    const response = await apim.get(
      `${FAVORITES_LIST_END_POINT}?lang=${locale}&limit=${FAVORITES_LIST_LIMIT}`
    )
    const { results } = response?.data ?? { results: [] }
    const listItems = getUserListLineItems(results)
    return [listItems, results]
  }
)
export const getMyAccountLists = createAsyncThunk(
  "getMyAccountLists",
  async () => {
    const config = await getConfig()
    const locale = config?.internationalization?.locale ?? "en-US"
    const response = await apim.get(
      `${FAVORITES_LIST_END_POINT}?lang=${locale}&limit=${FAVORITES_LIST_LIMIT}`
    )
    const { results } = response?.data ?? { results: [] }
    return results
  }
)
export const getMyAccountFavorites = createAsyncThunk(
  "getMyAccountFavorites",
  async query => {
    const config = await getConfig()
    const locale = config?.internationalization?.locale ?? "en-US"
    const response = await apim.get(`${favoritesEndPoint}?lang=${locale}`)
    const { results } = response.data
    if (results?.length > 0) {
      const getVersion = Math.max(...results.map(i => i.version))
      const d = results.filter(
        i => i.name[locale] === "Favorites" && i.version === getVersion
      )[0]
      if (d) {
        const { lineItems = [], textLineItems = [] } = d
        const productIds = lineItems.map(item => item.productId)
        const persona = getUserPersona()

        const fl = [
          "masterSKU_s",
          "SKUColorSwatchFilename_ss",
          "SKUColorSwatchFilename_s",
          "ProductDescriptionProductShort_s",
          "ProductBrandName_s",
          "metaTitle_s",
          "productName_s",
          "id",
          "productImages.url_s",
          "productImages.url_ss",
          "slug_s",
          "Product_Category",
          "displayPriceStartingAt_s",
          "Tile_Title",
          "ProductIsExclusive_s",
          "RegionReleaseforShipment_dt",
          "SKU.Details_ss",
          "Color.SKU.Details_ss",
          "Color.SKU.Details_s",
          "productImages.labelWithUrl_ss",
          "productImages.labelWithUrl_s",
          "variantList.sku_ss",
          "variantList.sku_s",
          "RegionBrandName_s",
          `priceList.${persona}.discountedPrice_d`,
          `priceList.${persona}.saleOffer_s`,
          `priceList.${persona}.finalPrice_d`,
          `priceList.${persona}.price_d`,
          `Color.${persona}.Details_ss`,
          "ctId_s",
          "Brand_ss",
          "Country_ss",
        ]

        const language = config?.internationalization?.language ?? "en"
        const res = await apim.get(`/search/full-catalog`, {
          params: {
            q: "*:*",
            fq: `ctId_s:("${productIds.join(
              '","'
            )}") && language_s:("${language}")`,
            fl: fl.join(","),
            collections: config?.general?.siteName ?? "Kohler",
            start: 0,
            rows: 50,
            sessionId: getSessionIdObjWithEpoc(),
          },
        })

        const productData = res.data?.response?.docs ?? []
        if (productData.length > 0) {
          lineItems.forEach((item, index, items) => {
            const product = productData.find(
              product => product["ctId_s"] === item?.productId
            )
            items[index].productData = product
          })
        }

        let sortLineItems = []
        if (lineItems.length > 0) {
          sortLineItems = lineItems.sort(function (a, b) {
            return new Date(b.addedAt) - new Date(a.addedAt)
          })
        }
        let sortTextLineItems = []
        if (textLineItems.length > 0) {
          sortTextLineItems = textLineItems.sort(function (a, b) {
            return new Date(b.addedAt) - new Date(a.addedAt)
          })
        }
        return {
          sortProducts: sortLineItems ?? [],
          sortMedia: sortTextLineItems ?? [],
          d,
        }
      } else {
        await store.dispatch(createFavorite())
      }
    }

    return null
  }
)

export const createFavorite = createAsyncThunk("createFavorite", async () => {
  const config = await getConfig()
  const locale = config?.internationalization?.locale ?? "en-US"
  const response = await apim.post(`${createFavoritesEndPoint}`, {
    name: { [locale]: shoppingListName },
  })
  return response.data ?? {}
})

export const addProductToList = createAsyncThunk(
  "addProductToList",
  async data => {
    const { sku, list, listData } = data
    let listId
    let version
    if (list?.id && list?.version) {
      listId = list?.id
      version = list?.version
    } else {
      const config = await getConfig()
      const locale = config?.internationalization?.locale ?? "en-US"
      const response = await apim.post(`${createFavoritesEndPoint}`, {
        name: { [locale]: listData?.name },
        project: { [locale]: listData?.project },
        description: { [locale]: listData?.description },
      })
      listId = response?.data?.id ?? ""
      version = response?.data?.version ?? ""
    }
    const body = {
      version,
      actions: [
        {
          action: "addLineItem",
          sku,
          custom: {
            type: {
              key: "lineItemShoppingList",
              typeId: "type",
            },
          },
        },
      ],
    }
    const response = await apim.post(
      `${addFavoritesEndPoint(listId, "product")}`,
      body
    )
    await store.dispatch(getMyAccountListsWithLineItems())
    return response?.data
  }
)

export const addProduct = createAsyncThunk(
  "addProduct",
  async (sku, thunkAPI) => {
    await store.dispatch(getFavorites())
    const state = thunkAPI.getState()
    let id
    let version
    let favorites
    const isPdp = window.eventPageType === "pdp"
    if (
      state?.favorites.favorites !== undefined &&
      Object.keys(state?.favorites.favorites).length > 0
    ) {
      favorites = state?.favorites?.favorites
      id = favorites?.id
      version = favorites?.version
    } else {
      const favoritesMain = await store.dispatch(createFavorite())
      favorites = favoritesMain?.payload

      id = favorites?.id
      version = favorites?.version
    }

    const body = {
      version,
      actions: [
        {
          action: "addLineItem",
          sku,
          custom: {
            type: {
              key: "lineItemShoppingList",
              typeId: "type",
            },
          },
        },
      ],
    }

    const response = await apim.post(
      `${addFavoritesEndPoint(id, "product")}`,
      body
    )
    if (isPdp && response.data) {
      addDataLayerFav(
        "",
        true,
        "add",
        window.internalLinkType ?? "",
        window.zoneName ?? "",
        "",
        ""
      )
    }
    return response.data
  }
)

export const removeItems = createAsyncThunk(
  "removeItems",
  async (ids, thunkAPI) => {
    let isDataLayerNeeded = true
    let imageName = ""
    let section = ""
    if (!Array.isArray(ids)) {
      isDataLayerNeeded = ids?.isDataLayerNeeded
      imageName = ids?.imageName
      section = ids?.section
      ids = ids.ids
    }
    try {
      const state = thunkAPI.getState()
      const { id, version, lineItems } = state?.favorites?.favorites
      if (id) {
        const actions = ids.map(pid => {
          if (lineItems.find(el => el.id === pid)) {
            return {
              action: "removeLineItem",
              lineItemId: pid,
            }
          } else {
            return {
              action: "removeTextLineItem",
              textLineItemId: pid,
            }
          }
        })

        const response = await apim.post(`${removeFavoritesEndPoint(id)}`, {
          version,
          actions,
        })
        isDataLayerNeeded &&
          addDataLayerFav(
            "",
            true,
            "remove",
            window.internalLinkType ?? "",
            section ?? "",
            "",
            "",
            imageName
          )
        return response.data
      }
      return {}
    } catch (error) {
      isDataLayerNeeded &&
        addDataLayerFav(
          "",
          false,
          "remove",
          window.internalLinkType ?? "",
          window.zoneName ?? "",
          "",
          ""
        )
    }
  }
)

export const myAccountRemoveItems = createAsyncThunk(
  "myAccountRemoveItems",
  async (ids, thunkAPI) => {
    const state = thunkAPI.getState()
    const { id, version, lineItems } = state?.favorites?.fullFavoriteData?.d
    if (id) {
      const actions = ids.map(pid => {
        if (lineItems.find(el => el.id === pid)) {
          return {
            action: "removeLineItem",
            lineItemId: pid,
          }
        } else {
          return {
            action: "removeTextLineItem",
            textLineItemId: pid,
          }
        }
      })

      const response = await apim.post(`${removeFavoritesEndPoint(id)}`, {
        version,
        actions,
      })
      if (response?.data) {
        const { lineItems = [], textLineItems = [] } = response?.data
        const productIds = lineItems.map(item => item.productId)
        const persona = getUserPersona()

        const fl = [
          "masterSKU_s",
          "SKUColorSwatchFilename_ss",
          "SKUColorSwatchFilename_s",
          "ProductDescriptionProductShort_s",
          "ProductBrandName_s",
          "metaTitle_s",
          "productName_s",
          "id",
          "productImages.url_s",
          "productImages.url_ss",
          "slug_s",
          "Product_Category",
          "displayPriceStartingAt_s",
          "Tile_Title",
          "ProductIsExclusive_s",
          "RegionReleaseforShipment_dt",
          "SKU.Details_ss",
          "Color.SKU.Details_ss",
          "Color.SKU.Details_s",
          "productImages.labelWithUrl_ss",
          "productImages.labelWithUrl_s",
          "variantList.sku_ss",
          "variantList.sku_s",
          "RegionBrandName_s",
          `priceList.${persona}.discountedPrice_d`,
          `priceList.${persona}.saleOffer_s`,
          `priceList.${persona}.finalPrice_d`,
          `priceList.${persona}.price_d`,
          `Color.${persona}.Details_ss`,
          "ctId_s",
          "Brand_ss",
          "Country_ss",
        ]

        const config = await getConfig()
        const language = config?.internationalization?.language ?? "en"
        const res = await apim.get(`/search/full-catalog`, {
          params: {
            q: "*:*",
            fq: `ctId_s:("${productIds.join(
              '","'
            )}") && language_s:("${language}")`,
            fl: fl.join(","),
            collections: config?.general?.siteName ?? "Kohler",
            start: 0,
            rows: 50,
            sessionId: getSessionIdObjWithEpoc(),
          },
        })

        const productData = res.data?.response?.docs ?? []
        if (productData.length > 0) {
          lineItems.forEach((item, index, items) => {
            const product = productData.find(
              product => product["ctId_s"] === item?.productId
            )
            items[index].productData = product
          })
        }

        let sortLineItems = []
        if (lineItems.length > 0) {
          sortLineItems = lineItems.sort(function (a, b) {
            return new Date(b.addedAt) - new Date(a.addedAt)
          })
        }
        let sortTextLineItems = []
        if (textLineItems.length > 0) {
          sortTextLineItems = textLineItems.sort(function (a, b) {
            return new Date(b.addedAt) - new Date(a.addedAt)
          })
        }
        return {
          sortProducts: sortLineItems ?? [],
          sortMedia: sortTextLineItems ?? [],
          d: response?.data ?? {},
        }
      }
    }
    return {}
  }
)

export const addMedia = createAsyncThunk(
  "addMedia",
  async (params, thunkAPI) => {
    const config = await getConfig()
    const locale = config?.internationalization?.locale ?? "en-US"
    const {
      type = "",
      name = "",
      url = "",
      description = "",
      articleType = "",
      thumbnail = "",
      needDataLayerToAdd = true,
      caption = "",
      section = "",
      videoName = "",
      videoId = "",
      errorMessage = "",
      successMsg = "",
      successCallback = () => {},
      errorCallback = () => {},
      imageName = "",
    } = params
    try {
      const state = thunkAPI.getState()
      let id
      let version
      let favorites
      if (state?.favorites?.favorites) {
        favorites = state?.favorites?.favorites
        id = favorites?.id
        version = favorites?.version
      } else {
        const favoritesMain = await store.dispatch(createFavorite())
        favorites = favoritesMain?.payload
        id = favorites?.id
        version = favorites?.version
      }
      const body = {
        version: version,
        actions: [
          {
            action: "addTextLineItem",
            name: {
              [locale]: name || videoName || "",
            },
            description: {
              [locale]: description,
            },
            custom: {
              type: {
                key: "textLineItemShoppingList",
                typeId: "type",
              },
              fields: {
                itemType: type,
                link: url,
                thumbnail,
                articleType,
              },
            },
          },
        ],
      }
      const response = await apim.post(
        `${addFavoritesEndPoint(id, type)}`,
        body
      )
      const { textLineItems = [] } = response.data
      if (textLineItems.length > 0) {
        checkIsFavorite(textLineItems)
        successCallback()
      }
      if (needDataLayerToAdd)
        addDataLayerFav(
          successMsg,
          true,
          "add",
          window.internalLinkType ?? caption,
          section,
          videoName,
          videoId,
          imageName
        )

      return response.data
    } catch (error) {
      if (needDataLayerToAdd)
        addDataLayerFav(
          errorMessage,
          false,
          "add",
          caption,
          section,
          videoName,
          videoId
        )

      errorCallback()
    }
  }
)

export const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {
    setFavorites(state, action) {
      state.favorites = action.payload
    },
    setLoginFavouriteCallback(state, action) {
      state.isLoginFavouriteCallback = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase([HYDRATE], (state, action) => {
        return {
          ...state,
          ...action.payload.favorites,
        }
      })
      .addCase(getFavorites.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(getFavorites.fulfilled, (state, action) => {
        state.favorites = action.payload

        state.status = "succeeded"
      })
      .addCase(getFavorites.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(getMyAccountListsWithLineItems.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(getMyAccountListsWithLineItems.fulfilled, (state, action) => {
        state.saveToListLineItems = action.payload[0]
        state.userLists = action.payload[1]
        state.status = "succeeded"
      })
      .addCase(getMyAccountListsWithLineItems.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(getMyAccountFavorites.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(getMyAccountFavorites.fulfilled, (state, action) => {
        state.fullFavoriteData = action.payload

        state.status = "succeeded"
      })
      .addCase(getMyAccountFavorites.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })

      .addCase(myAccountRemoveItems.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(myAccountRemoveItems.fulfilled, (state, action) => {
        state.fullFavoriteData = action.payload

        state.status = "succeeded"
      })
      .addCase(myAccountRemoveItems.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(addProduct.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.favorites = action.payload

        state.status = "succeeded"
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(createFavorite.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(createFavorite.fulfilled, (state, action) => {
        state.favorites = action.payload
        state.status = "succeeded"
      })
      .addCase(createFavorite.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(addMedia.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(addMedia.fulfilled, (state, action) => {
        state.favorites = { ...state.favorites, ...action.payload }
        state.status = "succeeded"
      })
      .addCase(addMedia.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(removeItems.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(removeItems.fulfilled, (state, action) => {
        state.favorites = action.payload
        state.status = "succeeded"
      })
      .addCase(removeItems.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
  },
})

export const { setFavorites, setLoginFavouriteCallback } =
  favoritesSlice.actions
export const selectFavoritesState = state => state.favorites
export default favoritesSlice.reducer
